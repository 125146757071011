<template>
<div class="why">
    <p class="why-title">
        چرا به بی بیتکس اعتماد کنیم؟
    </p>
    <div class="cell-containers">
        <div class="feature-beetex">
            <div class="for-text">
                <div class='feature-cell-title'>
                    <div class='feature-cell-text'></div>
                </div>
                <span class="feature-span">
                    مزایای <br> بی بیتکس
                </span>
            </div>
        </div>
        <div class="descrbe-cell-contsiner" :class=describe.class v-for="(describe , index) in describe" :key="index">
            <div class="for-text">
                <div class='title-cell-title'>
                    <div class='title-cell-text'></div>
                </div>
                <span class="title-span">
                    {{describe.text}}
                </span>
            </div>
            <div class="content">
                <inline-svg class="HomeHx" :src="require('../../assets/Home/HomeHx.svg')"></inline-svg>
                <div class="content-container">
                    <img :src=describe.img alt="">
                    <span class="title-content">
                        <span>مزایای</span> <span>بی بیتکس</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'WhyBeebitex',
    components: {
        InlineSvg
    },
    data() {
        return {
            describe: [{
                    img: require('../../assets/Home/support.png'),
                    title: "پشتیبانی آنی",
                    text: "لورم ایپسوم متن ساختگی با تولید سادگی از صنعت چاپ",
                    class: "odd"
                },
                {
                    img: require('../../assets/Home/variety.png'),
                    title: "پشتیبانی آنی",
                    text: "لورم ایپسوم متن ساختگی با تولید سادگی از صنعت چاپ",
                    class: "even"
                },
                {
                    img: require('../../assets/Home/support.png'),
                    title: "پشتیبانی آنی",
                    text: "لورم ایپسوم متن ساختگی با تولید سادگی از صنعت چاپ",
                    class: "odd"
                },
                {
                    img: require('../../assets/Home/invite.png'),
                    title: "پشتیبانی آنی",
                    text: "لورم ایپسوم متن ساختگی با تولید سادگی از صنعت چاپ",
                    class: "even"
                }
            ]
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.why {
    width: 100%;
    max-width: 1400px;
    margin: 40px 0;
}

.why-title {
    font-weight: bold;
    font-size: 24px;
    color: #F2F2F2;
    margin: 62px 0;
}

.cell-containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 30px;

    .feature-beetex {
        margin-top: 67px;

        .feature-span {
            position: absolute;
            color: #001E3D;
            font-weight: bold;
            font-size: 24px;
            top: 26px;
            right: 36px;
            text-align: center;
            transform: rotate(-90deg);
        }
    }
}

.descrbe-cell-contsiner {
    height: 348px;
    display: flex;
    flex-direction: column;
    width: 145px;
}

.odd {
    justify-content: flex-start;
}

.even {
    justify-content: center;
}

.for-text {
    position: relative;
    transform: rotate(90deg);

    .title-span {
        position: absolute;
        color: #FFFFFF;
        font-size: 14px;
        top: 31px;
        right: 23px;
        text-align: center;
        transform: rotate(-90deg);
    }

    .content {
        position: absolute;
        top: 11px;
        width: 111px;
        text-align: center;
        right: 18px;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
        transform: rotate(-90deg);
        height: 131px;

        span {
            font-weight: bold;
            font-size: 18px;
            color: #FFFFFF;
        }

    }
}

.HomeHx {
    position: absolute;
}

.title-content {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.descrbe-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.descrbe-cell-title *,
.descrbe-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.descrbe-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.descrbe-cell-title:first-child .descrbe-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: orange;
    content: '';
}

.title-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.title-cell-title *,
.title-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.title-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.title-cell-title:first-child .title-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: orange;
    content: '';
}

.feature-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.feature-cell-title *,
.feature-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.feature-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.feature-cell-title:first-child .feature-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: #FFDA00;
    content: '';
}

.inner-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.inner-cell-title *,
.inner-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.inner-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.inner-cell-title:first-child .inner-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: rgb(15, 15, 15);
    content: '';
}

.odd {
    flex-direction: column-reverse;
    justify-content: flex-end;

    // margin-right: -8px;
    .content {
        height: 135px;
        margin-right: -8px;
    }
}

.even {
    justify-content: flex-end;
    margin-top: -25px;

    // margin-right: -8px;
    .content {
        margin-right: -8px;

        .content-container {
            flex-direction: column-reverse;
            margin-top: 37px !important;
        }
    }
}

@media only screen and (max-width:600px) {
    .odd {}

    .even {
        flex-direction: column-reverse;

        .content {
            height: 137px;

            .content-container {
                flex-direction: column-reverse;
                margin-top: 37px !important;
            }
        }
    }

    .cell-containers {
        flex-direction: column !important;
        align-items: center !important;
    }
}
</style>
