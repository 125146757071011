<template>
<div class="edu-help">
    <svg height="100" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="0,0 0,100 100,100" />
    </svg>
    <div class="edu">
        <div class="access-title">
            <span>
                یادگیری با بی بیتکس
            </span>
            <button @click="$router.push('/blog/')">
                مشاهده بلاگ
            </button>
        </div>
        <div class="news-container">
            <div class="new" v-for="(news , index) in news" :key="index">
                <div class="layer">
                    <button class="new-btn">
                        اخبار
                    </button>
                    <p class="new-title">
                        لایتنینگ چیست و چطور از آن استفاده کنیم؟
                    </p>
                    <p class="new-seen">
                        2 ساعت قبل
                    </p>
                </div>
            </div>
        </div>
    </div>
    <svg height="100" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="100,100 100,0 0,0" />
    </svg>
</div>
</template>

<script>
export default {
    name: 'Education',
    components: {

    },
    data() {
        return {
            news: [{
                    title: "اخبار",
                },
                {
                    title: "اخبار",
                },
                {
                    title: "اخبار",
                }
            ]
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.edu-help {
    display: flex;
    flex-direction: column;
    margin-bottom: -103px;
}

.edu {
    width: 100%;
    background: #FFC04F;
    min-height: 200px;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
}

svg {
    fill: #FFC04F;
}

.news-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 20px;
}

.new {
    width: 282px;
    height: 168px;
    background-image: url('../../assets/Home/blog.png');
    box-sizing: border-box;
    padding: 23px;

    border-radius: 8px;
    position: relative;
}

.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 41, 61, 0.43);
    box-sizing: border-box;
    padding: 23px;
    border-radius: 8px;
}

.new-btn {
    background: #FFC04F;
    border-radius: 6px;
    font-weight: normal;
    font-size: 12px;
    color: #4F4F4F;
    width: 67px;
    height: 28px;
    border: none;
}

.new-title {
    font-weight: normal;
    font-size: 18px;
    color: #FFFFFF;
    margin: 10px 0 0 0;
}

.new-seen {
    font-weight: normal;
    font-size: 12px;
    color: #E0E0E0;
    margin: 0;
}

.access-title {
    display: flex;
    flex-direction: column;
    row-gap: 33px;

    button {
        background: #333333;
        border-radius: 4px;
        font-weight: normal;
        font-size: 12px;
        color: #FCFCFC;
        border: none;
        width: 102px;
        height: 32px;
    }

    span {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
    }
}
</style>
