<template>
<form @input="calculate" class="form">
    <SellModal v-if="show" @close="show = false" :unitPrice="unitPrice" :totalPrice="totalPrice" :amount="amount" type="BUY" :date="eDate" :limit="false" />
    <div class="inputs-container">
        <div class="amounts">
            <div>
                <label for="">
                    <input class="number-font" type="text" :placeholder="'قیمت'+'('+$coins[tradeTo].persianName+')'" disabled @input="unitPrice = $toLocal(unitPrice,$decimal[tradeTo])" v-model="unitPrice">
                </label>
            </div>
            <div>
                <div class="describe-input">
                    <span>
                        <img src="@/assets/Profile/walletForm.svg" alt="">&nbsp;
                        <span>موجودی</span>
                    </span>

                    <span class="number-font">
                        {{$toLocal(cash,$decimal[tradeFrom])}} &nbsp;
                        <span class="unit">{{$coins[tradeTo].persianName}}</span>
                    </span>
                </div>
                <label for="">
                    <input class="number-font" type="text" :placeholder="'مقدار'+'('+$coinLabel[tradeFrom]+')'" @input="amount = $toLocal(amount,$decimal[tradeFrom])" v-model="amount">
                    <p v-if="readonly" class="error-p">
                        {{readonly}}
                    </p>
                </label>
                <div class="range-container">
                    <InputRange @change="getAmountFromServer" :percent="percent" :id="3" />
                </div>
            </div>
            <div>
            </div>
        </div>
        <div>
            <div class="describe-input">
                <span>
                    کارمزد
                </span>
                <span class="number-font">
                    ٪ {{wage}}
                </span>
            </div>
            <label for="">
                <input class="number-font" type="text" placeholder="مجموع" v-model="totalPrice" @input="totalPrice = $toLocal(totalPrice,$decimal[tradeFrom])" disabled>

            </label>
        </div>
    </div>
    <button class="buy buy-btn" v-if="state.userInfo.authorized || state.userInfo.supervisor" :disabled="!!readonly" @click.prevent="showmodal">
        خرید {{$coinLabel[tradeFrom]}}
    </button>
</form>
</template>

<script>
import InputRange from '../../Public/InputRange.vue'
import SellModal from './SellModal.vue'
import {
    tradeTo,
    tradeFrom,
    precision,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: "BuyMarket",
    components: {
        InputRange,
        SellModal
    },
    props: ['cash', 'wage', 'decimal', 'limit', 'id', 'role', 'SellModal'],
    data() {
        return {
            SuccessSell: false,
            thisModal: true,
            //  min:54654654654,
            fee: 0.4,
            date_disable: true,
            // SellModal: false,
            eDate: '',
            percent: 0,
            unitPrice: '',
            amount: '',
            totalPrice: '',
            danger: false,
            danger1: false,
            danger2: false,
            show: false,
            value: '',
            checked: '',
            calculating: false,
            getAmount: false,
            amountTemp: '',
            count: 0,
            alive: true,
            loop: undefined,
            coins: '',
            priceError: "",
            amountError: "",
            minAmountOrder: null,
            maxAmountOrder: null,
            disableBuyBtn: true
        }
    },

    watch: {
        'state.buyAmount'() {
            this.amount = this.$toLocal(this.state.buyAmount, this.decimal)
            this.unitPrice = this.$toLocal(this.state.buyPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
            this.calculate()
        },
        'state.buyPrice'() {
            this.amount = this.$toLocal(this.state.buyAmount, this.decimal)
            this.unitPrice = this.$toLocal(this.state.buyPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
            this.calculate()
        }
    },
    computed: {
        now() {
            return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
        },
        readonly() {
            let priceError = ""
            if (this.limit && !this.$S2N(this.unitPrice)) {
                priceError = 'قیمت واحد اجباریست'
            } else if (!this.$S2N(this.amount)) {
                priceError = 'مقدار ارز اجباریست'
            } else if (this.$S2N(this.amount) < this.min) {
                priceError = 'مقدار ارز کمتر از حد مجاز است'
            } else if (this.$S2N(this.amount) > this.max) {
                priceError = 'مقدار ارز بیشتر از حد مجاز است'
            } else if ((this.$S2N(this.totalPrice) > this.$S2N(this.cash) || this.$S2N(this.totalprice) <= 0) && !this.state.userInfo.supervisor) {
                priceError = 'موجودی کافی نیست'
            } else if (!this.$S2N(this.totalPrice)) {
                priceError = 'صف فروش خالی است'
            }
            //  else if (this.load) {
            //     priceError = 'لطفا صبر کنید...'
            // } 
            else if (!this.limit && this.state.userInfo.supervisor) {
                priceError = 'بازارگردان قادر به انجام سفارش بازار نیست'
            }
            return priceError
        },
        load() {
            return this.calculating ||
                this.getAmount
        },
        min() {
            return this.role.minAmount
        },
        max() {
            return this.role.maxAmount
        },
        range() {
            return 'حداقل ' + this.$toLocal(this.min) + ' ' + this.$coinLabel[this.tradeFrom] +
                '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' ' + this.$coinLabel[this.tradeFrom]
        },
        tradeTo,
        tradeFrom,
        precision
    },
    methods: {
        disableBtn() {
            if (this.$S2N(this.amount) < this.minAmountOrder) {
                this.amountError = 'حداقل میزان معامله رعایت نشده'
                this.disableBuyBtn = true
            } else if (this.$S2N(this.amount) > this.maxAmountOrder) {
                this.amountError = 'حداکثر میزان معامله رعایت نشده'
                this.disableBuyBtn = true
            } else if ((this.$S2N(this.totalprice) > this.$S2N(this.cash) || this.$S2N(this.totalprice)) ||
                this.load ||
                (!this.limit && this.state.userInfo.supervisor)) {
                this.disableBuyBtn = true
            } else if (this.$S2N(this.totalPrice) > this.$S2N(this.cash)) {
                this.priceError = 'مقدار بیش از موجودی شماست'
                this.disableBuyBtn = true
            } else if (!this.amount || !this.unitPrice) {
                this.disableBuyBtn = true
            } else {
                this.disableBuyBtn = false
                this.amountError = ''
                this.priceError = ''
            }
        },
        setAmount(value) {
            if (this.cash > 0) {
                this.amount = (this.$S2N(this.cash)) * value
            } else this.amount = 0
            this.calculate()
        },
        putcash(p) {
            let up = this.$S2N(this.unitPrice)
            if (up) {
                this.percent = p
                let e = (this.percent * this.$S2N(this.cash)) / up
                this.amount = this.$toLocal(e, this.decimal)
                this.calculate()
                this.danger = 0
            } else {
                this.danger = 1
            }
        },
        setMin() {
            if (this.limit) {
                this.unitPrice = this.$toLocal(this.state.shopInfo.minSell, this.precision)
                this.danger = false
                this.calculate()
            }
        },
        localCalculate() {
            const up = this.$S2N(this.unitPrice)
            const a = this.$S2N(this.amount)
            this.totalPrice = this.$toLocal((up * a), this.$decimal[this.tradeTo])
            this.percent = this.$S2N(this.totalPrice) / this.$S2N(this.cash)
        },
        async serverCalculate() {
            this.loop ? this.loop.stop() : ''
            this.calculating = true
            const a = this.amount
            await this.$sleep(500)
            if (a === this.amount) {
                this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceBuy')
                this.loop.start()
            }
        },
        async getPriceFromServer() {
            let amount = this.$S2N(this.amount);
            const [res, ] = await this.$http.get('/orders/market-buy-sell-whole', {
                params: {
                    amount: amount,
                    marketType: this.tradeFrom + '_' + this.tradeTo,
                    orderType: 'MARKET_BUY'
                }
            })
            if (res) {
                this.totalPrice = this.$toLocal(res.baseDTO.wholePrice, this.$decimal[tradeTo]);
                this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount), this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom]);
                this.percent = this.$S2N(this.totalPrice) / this.$S2N(this.cash)

            }
            this.calculating = false
            this.disableBtn()
        },
        async getAmountFromServer(p) {
            this.percent = p || this.percent
            let e = this.percent * this.$S2N(this.cash)
            if (e) {
                this.getAmount = true
                const [res, ] = await this.$http.get('/orders/amount-via-price', {
                    params: {
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY',
                        amount: e
                    }
                })
                this.getAmount = false
                if (res) {
                    this.amount = this.$toLocal(res.baseDTO.wholePrice, this.decimal)
                    this.calculate()
                }
            }
        },
        calculate() {
            if (this.limit && this.unitPrice && this.amount) {
                this.localCalculate()
            } else if (!this.limit && this.$S2N(this.amount)) {
                this.serverCalculate()
            }
        },
        showmodal() {
            if (!(!this.limit || this.$S2N(this.unitPrice))) {
                this.danger = true
                this.danger1 = false
                this.danger2 = false
            } else if (!this.$S2N(this.amount)) {
                this.danger1 = true
                this.danger2 = false
                this.danger = false
            } else if (this.percent > 1 && !this.state.userInfo.supervisor) {
                this.danger2 = true
                this.danger1 = false
                this.danger = false
            } else {
                this.show = true
                this.danger = false
                this.danger1 = false
                this.danger2 = false
            }
        },
        async getCoins() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            this.minAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).minAmount
            this.maxAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).maxAmount
        },
    },
    mounted() {
        this.getCoins()
    },
    beforeDestroy() {
        (this.loop ? this.loop.stop() : '')
    }

}
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    flex-grow: 1;
}

.describe-input {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    color: #E2E2E2;

    span {
        display: flex;
        align-items: center;

        .unit {
            color: #BDBDBD;
        }
    }
}

input[type="text"] {
    height: 35px !important;
}

.ex-date {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: normal;
    font-size: 12px;
    color: var(--font-strong);
}

input[type="checkbox"] {
    background: #2E414E;
    border: 1px solid rgba(199, 199, 199, 0.22);
    border-radius: 2px;
    width: 16px;
    height: 16px;
}

.calender {
    position: absolute;
    top: 5px;
    left: 10px;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    .amounts {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
}

.buy,
.buy-btn {
    background: linear-gradient(90deg, #9DE700 0%, #5AB400 51.76%, #91D600 101.82%);
    font-weight: bold;
    color: #FCFCFC;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border-radius: 6px;
}

.range-container {
    position: relative;
}
</style>
