<template>
<div class="sellbuy">
    <ExchangeModal :data="data" v-if="exModal" @close="exModal = false" :beebitexWallet="beebitexWallet" @submit="sendData" :unit="toman" :buy="buy" />
    <SuccessExchange :data="temptData" v-if="success" @close="success = false; ++state.orderChange;" :toman='toman' :buy="buy" />
    <div class="form-container">
        <div>
            <div class="form-title">
                <div v-for="(title , index) in titles" :key="index" class="deActive-title" @click="toggle(index)" :class="{'buy' : buy && title.class=='buy' , 'sell' : !buy && title.class=='sell'}">
                    {{title.title}}
                </div>
            </div>
            <div class="form-info">
                <span>
                    <span v-if="buy">
                        قیمت خریداز ما
                    </span>
                    <span v-if="!buy">
                        قیمت فروش به ما
                    </span>
                </span>
                <span dir="ltr">

                    <span dir="rtl"> {{$toLocal(state[buy ? 'exchangeBuyPrice' : 'exchangeSellPrice'],toman ? 0 : $prcesion[coin])}} {{$coins[toman ? 'TOMAN':'TETHER'].persianName}} </span>

                    <span> = 1 {{$coins[coin].code}}</span>
                    <!-- <span v-if="toman">
                        تومان
                    </span>
                    <span v-else>
                        تتر
                    </span> -->
                </span>
            </div>
            <div class="inputs">
                <div class="inputs-info">
                    <img src="@/assets/Profile/sellBuy.svg" alt="">
                    <span v-if="!buy">
                        <span dir="ltr">{{$toLocal(walletBalance.credit,$decimal[coin])}} {{$coins[coin].code}}</span>

                        <!-- {{walletBalance.credit}} -->
                    </span>
                    <span v-else>
                        <span>{{$toLocal(BuywalletBalance.credit,$decimal[toman ? 'TOMAN':'TETHER'])}}</span>&nbsp;
                        {{$coins[toman ? 'TOMAN':'TETHER'].persianName}}
                    </span>
                </div>
                <label>
                    <input type="text" placeholder="نوع ارز" disabled>
                    <div class="crypto-drop" @click="drop=!drop">
                        <div class="drop-name">
                            <img class="drop-img" :src="'/coins/'+ coin + '.png'" :alt="coin">
                            <span>
                                {{$coins[coin].persianName}}
                            </span>
                        </div>
                        <img src="@/assets/Home/Vector.svg" alt="">
                        <CryptoDropDown v-if="drop" @selectCoin='selectedCoin' />
                    </div>
                </label>
                <input type="text" placeholder="مقدار" v-model="data.amount" @input="AmountFun">
                <p class="error-p" v-if="(buy && buyReadOnly) || (!buy && sellReadOnly)">
                    {{buy ? buyReadOnly : sellReadOnly}}
                </p>
                <label for="" class="unit-price">
                    <input type="text" placeholder="مبلغ معامله" disabled :value="data.totalPrice" @input="data.totalPrice = $toLocal(data.totalPrice)">
                    <div class="unit" @click="selectUnit">
                        <div class="unit-title">
                            <span v-if="toman">
                                <img class="unit-icons" src="/coins/TOMAN.png" alt="">
                                تومان
                            </span>
                            <span v-else>
                                <img class="unit-icons" src="/coins/TETHER.png" alt="">
                                تتر
                            </span>

                        </div>
                        <div class="unit-icons">
                            <img src="../../../assets/Home/Vector.svg" alt="" class="up-index">
                            <img src="../../../assets/Home/Vector.svg" alt="" class="down-index">
                        </div>
                    </div>
                </label>

            </div>
            <div class="minmax">
                <div class="minmax-container">
                    <span class="minmax-title">
                        حداقل میزان تراکنش سفارش
                    </span>
                    <span class="minmax-amount">
                        {{minAmountExchange}}
                    </span>
                </div>
                <div class="minmax-container">
                    <span class="minmax-title">
                        حداکثر میزان تراکنش سفارش
                    </span>
                    <span class="minmax-amount">
                        {{$toLocal(maxAmountExchange)}}
                    </span>
                </div>
            </div>
            <div class="wallet" v-if="buy">
                <span class="wallet-title">
                    انتقال ارز به
                </span>
                <div class="two-wallet">
                    <div :class="{'active-wallet' : beebitexWallet , 'deActive-wallet' : !beebitexWallet}" @click="beebitexWallet=true;buyReadOnly()">
                        کیف پول بی بیتکس
                    </div>
                    <div :class="{'active-wallet' : !beebitexWallet, 'deActive-wallet' : beebitexWallet}" @click="beebitexWallet=false;buyReadOnly()">
                        کیف پول دیگر
                    </div>
                </div>
                <label for="" class="selectd-net-lable" v-if="!beebitexWallet">
                    <div class="selectd-net" @click="netDrop = !netDrop">
                        <span v-if="selected_net">{{selected_net}}</span>
                        <span v-else>انتخاب شبکه</span>
                    </div>
                    <div class="netDropdown" v-if="netDrop">
                        <div v-for="(net , index) in nets" :key="index" @click="selectFun(index);buyReadOnly()">
                            {{net.tokenType}}
                        </div>
                    </div>
                </label>
                <label for="" v-if="!beebitexWallet">
                    <input type="text" v-model="data.destinationWallet" placeholder="آدرس کیف پول" @input="buyReadOnly">
                </label>
            </div>
        </div>
        <button v-if='buy' class='buy-btn' @click="buyModal" :disabled="!!buyReadOnly">
            خرید
        </button>
        <button v-else class='sell-btn' @click="sellModal" :disabled="!!sellReadOnly">
            فروش
        </button>
    </div>
</div>
</template>

<script>
import CryptoDropDown from '../../Public/CryptoDropDown.vue'
import ExchangeModal from './ExchangeModal.vue'
import SuccessExchange from './SuccessExchange.vue'
// import {
//     Loop
// } from "@/library/reuseableFunction";
export default {
    name: 'SellBuy',
    components: {
        CryptoDropDown,
        ExchangeModal,
        SuccessExchange

    },
    data() {
        return {
            temptData: {},
            sellAmount: "",
            disableSell: true,
            buyAmount: "",
            disableBuy: true,
            buy: false,
            drop: false,
            loop: undefined,
            coin: 'BITCOIN',
            walletBalance: {
                credit: 0,
            },
            BuywalletBalance: {
                credit: 0,
            },
            loading: false,
            prices: "",
            beebitexWallet: true,
            netDrop: false,
            selected_net: "",
            nets: [],
            coins: [],
            txIdIsNeeded: false,
            totalPrice: null,
            exModal: false,
            toman: true,
            success: false,
            minAmountExchange: '',
            maxAmountExchange: '',
            data: {
                marketType: "TRON_TOMAN",
                amount: null,
                sourceCoin: "TOMAN",
                destinationCoin: "TRON",
                orderType: "EXCHANGE_BUY",
                tokenType: "",
                destinationWallet: "",
                unitPrice: '',
                totalPrice: ''
            },
            titles: [{
                    title: "خرید",
                    class: "buy",
                    value: true
                },
                {
                    title: "فروش",
                    class: "sell",
                    value: false
                },

            ],
            wallets: [{
                    title: "کیف پول بی بیتکس",
                    class: "",
                    active: true,
                },
                {
                    title: "کیف پول دیگر",
                    class: "",
                    active: false,
                }
            ],
            inputs: [{
                    title: "نوع ارز"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "مبلغ معامله"
                }
            ]
        }
    },
    watch: {
        'state.exchangeBuyPrice'() {
            this.calcPrice()
        },
        'state.exchangeSellPrice'() {
            this.calcPrice()
        }
    },
    computed: {
        readonly() {
            return (this.limit && !this.$S2N(this.unitPrice)) ||
                !this.$S2N(this.amount) ||
                this.$S2N(this.amount) < this.min ||
                this.$S2N(this.amount) > this.max
                // || this.$S2N(this.amount) > this.$S2N(this.cash)
                ||
                (this.percent > 1 && !this.state.userInfo.supervisor) ||
                this.load ||
                (!this.limit && this.state.userInfo.supervisor)
        },

        sellReadOnly() {
            let error = ''
            if (!this.$A2N(this.data.amount)) {
                error = 'مقدار ارز اجباری است'
                // this.disableSell = true
            } else if (this.$A2N(this.data.amount) < this.minAmountExchange) {
                error = 'حداقل مقدار رعایت نشده'
                // this.disableSell = true
            } else if (this.$S2N(this.data.amount) > this.walletBalance.credit) {
                error = 'مقدار بیش از موجودی شماست'
                // this.sellAmount = 'مقدار بیش از موجودی شماست'
                // this.disableSell = true
            } else if (this.$A2N(this.data.amount) > this.maxAmountExchange) {
                error = 'حداکثر مقدار رعایت نشده'
                // this.sellAmount = 'حداکثر مقدار رعایت نشده'
                // this.disableSell = true
            }
            return error
            //  else {
            //     this.disableSell = false
            //     this.sellAmount = ''
            // }

        },
        buyReadOnly() {
            let error = ''
            if (!this.$A2N(this.data.amount)) {
                error = 'مقدار ارز اجباری است'
            } else if (this.$A2N(this.data.amount) < this.minAmountExchange) {
                error = 'حداقل مقدار رعایت نشده'
            } else if (this.$A2N(this.data.totalPrice) > this.BuywalletBalance.credit) {
                error = 'مقدار بیش از موجودی شماست'
            } else if (this.$A2N(this.data.amount) > this.maxAmountExchange) {
                error = 'حداکثر مقدار رعایت نشده'
            } else if (!this.beebitexWallet) {
                if (this.data.destinationWallet == '') {
                    error = ' آدرس کیف پول اجباری است '
                } else if (this.data.tokenType == '') {
                    error = 'انخاب شبگه اجباری است'

                }
            }
            return error
        },
    },
    methods: {

        toggle(index) {
            this.buy = this.titles[index].value
            // this.calcUnitPrice()
            this.data.amount = ''
            this.data.totalPrice = ''
        },
        selectUnit() {
            this.toman = !this.toman
            this.$router.push({
                query: {
                    ...this.$route.query,
                    to: this.toman ? 'TOMAN' : 'TETHER'
                }
            })
            this.$emit('changeUnit', this.toman)
            // this.getPrice();
            this.calcPrice()
            this.tomanTetherWallet()

        },
        AmountFun() {
            this.data.amount = this.$toLocal(this.data.amount, this.$decimal[this.coin])
            // if (this.buy) {
            //     // this.buyReadOnly()
            // } else if (!this.buy) {
            //     // this.sellReadOnly()
            // }

            this.calcPrice()

        },
        sellModal() {
            this.data.marketType = this.coin + '_TOMAN'
            this.data.destinationCoin = this.coin
            this.data.orderType = 'EXCHANGE_SELL'
            this.exModal = true
        },
        buyModal() {
            this.data.marketType = this.coin + '_TOMAN'
            this.data.destinationCoin = this.coin
            this.data.orderType = 'EXCHANGE_BUY'
            this.exModal = true
        },
        async sendData() {
            this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.temptData.amount)
            this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice)
            this.temptData.destinationCoin = this.data.destinationCoin
            this.temptData.unitPrice = this.$S2N(this.state[this.buy ? 'exchangeBuyPrice' : 'exchangeSellPrice'])
            this.exModal = false
            if (this.buy) {
                if (this.beebitexWallet) {
                    delete this.temptData.destinationWallet
                    delete this.temptData.tokenType

                    this.state.loading = true
                    await this.$axios.post(
                        '/exchange/buy', this.temptData
                    )
                    this.success = true
                    this.$emit('reloadHistory')

                } else {

                    this.state.loading = true
                    await this.$axios.post(
                        '/exchange/buy', this.temptData
                    )
                    this.success = true
                    this.$emit('reloadHistory')

                }

            } else {

                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/sell', this.temptData
                )
                this.success = true
                this.$emit('reloadHistory')

                this.state.loading = false
            }

        },
        async getWallets() {
            this.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + this.coin)
            if (res) {
                this.walletBalance = res[this.coin]
            }
            this.loading = false
        },
        async tomanTetherWallet() {
            this.loading = true
            const [res2, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + (this.toman ? 'TOMAN' : 'TETHER'))
            if (res2) {
                this.BuywalletBalance = res2[this.toman ? 'TOMAN' : 'TETHER']
            }
            this.loading = false
        },
        // async getPrice() {
        //     const res = await this.$axios.get('coins/exchange-price/' + (this.toman ? 'usd' : 'usdt') + '?includeWeeklyChange=false&destCoinSymbol=' + this.coin)
        //     this.prices = res
        //     this.calcUnitPrice()
        //     this.calcPrice()
        // },
        // calcUnitPrice() {
        //     if (this.buy) {
        //         this.data.unitPrice = this.prices.find(a => a.destCoinSymbol == this.coin).sellPrice
        //     } else {
        //         this.data.unitPrice = this.prices.find(a => a.destCoinSymbol == this.coin).buyPrice
        //     }
        // },
        calcPrice() {
            if (this.data.amount) {
                this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.state[this.buy ? 'exchangeBuyPrice' : 'exchangeSellPrice'], this.$decimal[this.toman ? 'TOMAN' : 'TETHER'])
            }
        },
        async getCoins() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            this.getNets()
        },

        getNets() {
            this.nets = this.coins.find((a) => a.coin === this.coin).tokenTypeDetails
        },
        titlesclick() {
            if (this.buyTtile) {
                return true
            } else {
                this.buyTtile = true
                this.sellTitle = false
            }
        },
        async selectedCoin(value) {
            this.coin = value.destCoinSymbol
            this.$router.push({
                query: {
                    ...this.$route.query,
                    from: this.coin
                }
            })
            // await this.getPrice()
            this.getWallets()
            this.getNets()
            this.calcPrice()
            this.MinMaxAmount()
        },
        MinMaxAmount() {
            this.minAmountExchange = this.coins.find(a => a.coin == this.coin).minAmountExchange
            this.maxAmountExchange = this.coins.find(a => a.coin == this.coin).maxAmountExchange
        },
        selectFun(index) {
            this.selected_net = this.nets[index].tokenType
            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
            this.data.tokenType = this.nets[index].tokenType
            this.netDrop = false

        },
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }

    },
    async mounted() {
        this.coin = this.$route.query.from || 'BITCOIN'
        this.toman = this.$route.query.to !== 'TETHER'
        this.getWallets()
        this.tomanTetherWallet()
        // this.getPrice()
        await this.getCoins()
        // this.calcUnitPrice()
        this.MinMaxAmount()
    }
}
</script>

<style lang="scss" scoped>
.sellbuy {
    flex-grow: 1;
    width: 100%;
    max-width: 380px;
    min-width: 300px;
    min-height: 531px;
    background: #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 2px;
}

.form-container {
    background: #001E3D;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.deActive-title {
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1F3946;
    color: #BDBDBD;
    cursor: pointer;
}

.buy-btn,
.sell-btn {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    margin-top: 15px;
}

.buy,
.buy-btn {
    background: linear-gradient(90deg, #9DE700 0%, #5AB400 51.76%, #91D600 101.82%);
    font-weight: bold;
    color: #FCFCFC;
    cursor: pointer;
}

.sell,
.sell-btn {
    background: linear-gradient(270deg, rgba(255, 47, 1, 0.97) 0%, rgba(255, 61, 0, 0.979333) 32.6%, rgba(255, 92, 0, 0.984313) 64.37%, #FF3D00 100%);
    font-weight: bold;
    color: #FCFCFC;
    cursor: pointer;
}

.two-wallet {
    max-width: 300px;
    height: 36px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    box-shadow: -3px 7px 18px rgba(17, 15, 15, 0.25);

    div {
        cursor: pointer;
    }
}

.wallet {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    font-weight: normal;
    font-size: 14px;
    color: #FCFCFC;
}

.active-wallet,
.deActive-wallet {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.active-wallet {
    color: #FCFCFC;
    background: linear-gradient(94.21deg, #FFF504 0%, #D68D00 47.18%, #F48400 99.06%);
    font-weight: bold;
}

.deActive-wallet {
    font-weight: normal;
    color: #BDBDBD;
    background: #1F3946;
}

.form-title {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    height: 32px;
}

.form-info {
    font-weight: bold;
    font-size: 12px;
    color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.inputs {
    // margin-bottom: 20px;

    input {
        margin: 8px 0;
        height: 36px !important;
    }

    .inputs-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: normal;
        font-size: 12px;
        color: #E0E0E0;
    }
}

.crypto-drop {
    position: absolute;
    background: rgba(159, 159, 159, 0.35);
    border-radius: 4px;
    height: 36px;
    width: 40%;
    left: 0;
    top: -5px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .drop-img {
        width: 24px;
        height: 24px;
    }

    .drop-name {
        display: flex;
        column-gap: 7px;
        align-items: center;
        font-weight: normal;
        font-size: 12px;
        color: #FEFEFE;
    }
}

.selectd-net {
    cursor: pointer;
    background: var(--background-input);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    color: var(--font-strong);
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.netDropdown {
    position: absolute;
    width: 100%;
    background: gray;
    padding: 10px;
    z-index: 2;

    div {
        cursor: pointer;
    }
}

.unit-price {
    display: flex;

    span {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    .unit {
        position: absolute;
        background: rgba(159, 159, 159, 0.35);
        border-radius: 4px;
        height: 36px;
        width: 40%;
        left: 0;
        top: 8px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #FEFEFE;
    }

}

.unit-icons {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    width: 20px;
}

.up-index {
    transform: rotate(180deg);

    path {
        fill: red;
    }
}

.minmax {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .minmax-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .minmax-title {
            color: #F2F2F2;
            font-weight: bold;
            font-size: 12px;
        }

        .minmax-amount {
            color: #E0E0E0;
            font-size: 12px;
        }
    }
}
</style>
