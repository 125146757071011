<template>
    <div class="personal">
        <span class="personal-title">
          اطلاعات شخصی
        </span>
        <!-- <div class="inputs-container">
          <input type="text" v-for="(inputs , index) in inputs" :key="index" :placeholder=inputs.title>
        </div>
        <label >
          <input type="text" placeholder="تاریخ تولد" class="date-born">
        </label>
        <label for="">
          <textarea rows="3" placeholder="آدرس"></textarea>
        </label> -->
        <form class="inputs-container">
                <input
                        placeholder="نام"
                        @input="data.firstName = $S2Fa(data.firstName)"
                        type="text"
                        v-model="data.firstName"
                        id="fname"
                        :readonly="readonly">
                <input
                        placeholder="نام خانوادگی"
                        @input="data.lastName = $S2Fa(data.lastName)"
                        type="text"
                        v-model="data.lastName"
                        id="lname"
                        :readonly="readonly">
                <input
                        placeholder="کد ملی"
                        @input="data.nationalCode = $A2N(data.nationalCode)"
                        type="nationalid"
                        v-model="data.nationalCode"
                        name="nationalid"
                        id="nationalid"
                        maxlength="10"
                        :readonly="readonly">
                <date-picker
                        id="birthday"
                        v-model="data.birthDay"
                        placeholder="تاریخ تولد"
                        
                        :format="$dateFormat['en']"
                        :display-format="$dateFormat['fa']"
                        :max="$jmoment().format($dateFormat['en'])"
                        :clearable="!state.userInfo.authorized"
                        :disabled="readonly"/>
                <input
                        placeholder="تلفن همراه"
                        @input="data.mobileNumber = $A2N(data.mobileNumber)"
                        type="text"
                        v-model="data.mobileNumber"
                        name="mobile"
                        id="mobile"
                        minlength="11"
                        maxlength="11"
                >
                <input
                        @input="data.telephone = $A2N(data.telephone)"
                        type="text"
                        v-model="data.telephone"
                        name="phone"
                        id="phone"
                        minlength="11"
                        maxlength="11"
                        placeholder="تلفن ثابت"
                >
                <!-- <input
                        placeholder="آدرس ایمیل"
                        v-model="state.userInfo.email"
                        name="nationalid"
                        id="nationalid"
                        type="text"
                        disabled> -->
                <textarea
                        v-model="data.address"
                        name="address"
                        id="address"
                        style="resize:none"
                        placeholder="آدرس"
                        rows="3"
                />
        </form>
    </div>
</template>

<script>
import '@/style/birthdayPicker.scss'

// import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
export default {
  name: 'PersonalInformation',
  components :{
    // datePicker: VuePersianDatetimePicker,
  },
  props: ['data'],
  data() {
      return {
         inputs : [
           {
             title:"نام"
           },
           {
             title:"نام خانوادگی"
           },
           {
             title:"کد ملی"
           },
           {
             title:"آدرس ایمیل"
           },
           {
             title:"تلفن همراه"
           },
           {
             title:"تلفن ثابت"
           }
         ]
      }
  },
  computed: {
        readonly() {
            return ['AUTHORIZED', "INFORMATION_CHANGED", 'INFORMATION_CHANGED_PENDING'].includes(this.state.userInfo.authenticationType)
        }
    },
  methods : {
      
  },
  }
</script>

<style lang="scss" scoped>
    .personal {
      background: #001E3D;
      border-radius: 8px;
      flex-grow: 1;
      min-height: 300px;
      box-sizing: border-box;
      padding: 24px;
      display: flex;
      flex-direction: column;
      row-gap: 32px;
        .personal-title {
          font-weight: bold;
          font-size: 18px;
          color: #FFFFFF;
        }
        .inputs-container {
          display: flex;
          flex-wrap: wrap;
          row-gap: 20px;
          justify-content: space-around;
          column-gap: 20px;
          row-gap: 25px;
            input , #birthday{
              flex-grow: 1;
              // min-width: 250px;
              max-width: 430px;
            }
        }
    }
    textarea {
      width: 100%;
      background: rgba(105, 105, 105, 0.38);
      border: 1px solid rgba(199, 199, 199, 0.22);
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: normal;
      font-size: 12px;
      color: #FEFEFE;
      padding: 10px;
      resize: none;
    }
    textarea::placeholder {
      font-weight: normal;
      font-size: 12px;
      color: #FEFEFE;
    }
    .for-personal {
      height: 36px !important;
      opacity: 0;
    }
    .date-born {
      max-width: 362px;
    }
</style>
