<template>
<div class="about">
    <div class="about-container">
        <p class="about-title">
            درباره
        </p>
        <p class="name">
            بی بیتکس
        </p>
        <p class="describe">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطر آنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد
        </p>
        <div class="featurse-help">

            <div class="featurse-container">
                <div class="f12">
                    <div class="feature1">
                        <div class='title-cell-title'>
                            <div class='title-cell-text'></div>
                        </div>
                        <div class="content">
                            <img src="" alt="">
                            <span>
                                <span>مزایای</span> <span>بی بیتکس</span>
                            </span>
                        </div>
                    </div>
                    <div class="feature2">
                        <div class='descrbe-cell-title'>
                            <div class='descrbe-cell-text'></div>
                        </div>
                        <div class="content-describe">
                            <img src="@/assets/Home/support.png" alt="">
                            <span>
                                <span>مزایای</span> <span>بی بیتکس</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="f12">
                    <div class="feature1">
                        <div class='descrbe-cell-title'>
                            <div class='descrbe-cell-text'></div>
                        </div>
                        <div class="content-describe">
                            <img src="@/assets/Home/variety.png" alt="">
                            <span>
                                <span>مزایای</span> <span>بی بیتکس</span>
                            </span>
                        </div>
                    </div>
                    <div class="feature2">
                        <div class='descrbe-cell-title'>
                            <div class='descrbe-cell-text'></div>
                        </div>
                        <div class="content-describe">
                            <img src="@/assets/Home/support.png" alt="">
                            <span>
                                <span>مزایای</span> <span>بی بیتکس</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="f12">
                    <div class="feature1">
                        <div class='descrbe-cell-title'>
                            <div class='descrbe-cell-text'></div>
                        </div>
                        <div class="content-describe">
                            <img src="@/assets/Home/invite.png" alt="">
                            <span>
                                <span>مزایای</span> <span>بی بیتکس</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="statistics-container">
        <svg height="45" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="100,100 0,100 0,0" />
        </svg>

        <div class="statistics">
            <div class="statistic">
                <span class="number number-font">
                    {{$toLocal(userscount)}}
                </span>
                <span class="title">
                    تعداد کاربران
                </span>
            </div>
            <div class="statistic">
                <span class="number number-font">
                    {{$toLocal(tradprice.toFixed(0))}}
                </span>
                <span class="title">
                    حجم معاملات
                </span>
            </div>
            <div class="statistic">
                <span class="number number-font">
                    {{$toLocal(totaltrad)}}
                </span>
                <span class="title">
                    تعداد معاملات
                </span>
            </div>
        </div>
        <svg height="45" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="100,100 100,0 0,0" />
        </svg>
    </div>
    <div class="about-container">
        <p class="describe">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطر آنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد
        </p>
        <p class="describe">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطر آنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: 'AboutUs',
    components: {

    },
    data() {
        return {
            userscount: '0',
            tradprice: '0',
            totaltrad: '0'
        }
    },
    methods: {
        async getInfo() {
            const res = await this.$axios('trades/market-info-home')
            this.userscount = res.baseDTO.userCount
            this.tradprice = res.baseDTO.tradesValue
            this.totaltrad = res.baseDTO.tradesAmount
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style lang="scss" scoped>
.about {
    width: 100%;
    // max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
}

.about-container {
    width: 100%;
    max-width: 1200px;
}

.about-title {
    font-weight: bold;
    font-size: 24px;
    color: #F2F2F2;
    margin: 32px 0 8px 0;

}

.name {
    font-weight: bold;
    font-size: 48px;
    color: #FFC04F;
    margin: 0 0 32px 0;
}

.describe {
    font-weight: normal;
    font-size: 18px;
    color: #FFFFFF;
}

.statistics-container {
    width: 100%;
    min-height: 287px;
    display: flex;
    flex-direction: column;

}

.statistics {
    width: 100%;
    min-height: 287px;
    background-color: #001E3D;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 100px;
}

.statistic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
    justify-content: space-between;
    align-items: center;

    .number {
        font-weight: bold;
        font-size: 36px;
        color: #FFFFFF;
    }

    .title {
        font-weight: bold;
        font-size: 24px;
    }

    .unit {
        font-weight: normal;
        font-size: 24px;
    }
}

svg {
    fill: #001E3D;
}

.featurse-container {
    width: 100%;
    display: flex;
    min-height: 245px;
    position: relative;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 35px;
    overflow: hidden;
}

.f12 {
    // display: flex;
    flex-direction: column;
    width: 250px;
    position: relative;
    min-width: 250px;
}

.f122 {
    display: flex;
    flex-direction: column;
    width: 303px;
    position: relative;
    margin-right: -55px;
    min-width: 250px;
}

.f1222 {
    display: flex;
    flex-direction: column;
    width: 303px;
    position: relative;
    // position: absolute;
    top: 0;
    right: 490px;
}

.feature1 {
    width: 100%;
    // height: 245px;
    // display: flex;
    align-items: flex-start;
    position: relative;
}

.feature2 {
    width: 100%;
    // height: 245px;
    // display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    // position: absolute;
    // right: 25px;
    // bottom: 45px;
    margin-top: -71px;
    margin-right: 123px;
    position: relative;
    // position: absolute;
}

.descrbe-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-60deg) skewX(30deg);
    border-radius: 6px;
}

.descrbe-cell-title *,
.descrbe-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.descrbe-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.feature1 {
    .title-cell-title {
        overflow: hidden;
        display: inline-block;
        // margin: 4em 0;
        width: 150px;
        height: 130px;
        transform: rotate(-60deg) skewX(30deg);
        border-radius: 6px;
    }

    .title-cell-title *,
    .title-cell-title *:before {
        display: block;
        overflow: hidden;
        width: inherit;
        height: inherit;
        border-radius: inherit;
    }

    .title-cell-text {
        transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    }

    .title-cell-title:first-child .title-cell-text:before {
        transform: skewX(-30deg) rotate(60deg) skewX(30deg);
        background: #FFD600;
        content: '';
    }

    .featurse-help {
        display: flex;
        justify-content: center;

    }

    .content {
        position: absolute;
        top: 20px;
        width: 111px;
        text-align: center;
        right: 15px;

        span {
            font-weight: bold;
            font-size: 24px;
        }
    }

}

.descrbe-cell-title:first-child .descrbe-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: orange;
    content: '';
}

.featurse-help {
    display: flex;
    justify-content: center;
}

.content {
    position: absolute;
    top: 20px;
    width: 111px;
    text-align: center;
    right: 15px;

    span {
        font-weight: bold;
        font-size: 24px;
    }
}

.content-describe {
    position: absolute;
    top: 11px;
    width: 111px;
    text-align: center;
    right: 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;

    span {
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
    }
}

.feature1 .title {}

@media only screen and (max-width:800px) {
    .f122 {
        margin-right: 0;
    }
}
</style>
