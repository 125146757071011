<template>
<div class="wallet-balance">
    <div class="wallet-container">
        <div class="title-container">
            <span class="wallet-title">
                موجودی
            </span>
            <a href="/profile/wallet" @click.prevent="$router.push('/profile/wallet')">
                <span class="observe">
                    مشاهده کیف پول
                </span>
            </a>
        </div>
        <div class="info-container">
            <div class="info">
                <span class="wallet-title">
                    {{$coinLabel[tradeTo]}}
                </span>
                <span class="number-font wallet-title">
                    {{$toLocal(base.credit,tradeTo === 'TOMAN' ? 0 : $decimal[tradeTo])}}
                </span>
            </div>
            <div class="info">
                <span class="wallet-subtitle">
                    قابل برداشت
                </span>
                <span class="number-font wallet-subtitle">
                    {{$toLocal(base.credit,tradeTo === 'TOMAN' ? 0 : $decimal[tradeTo])}}
                </span>
            </div>
        </div>
        <div class="info-container">
            <div class="info">
                <span class="wallet-title">
                    {{$coinLabel[tradeFrom]}}
                </span>
                <span class="number-font wallet-title">
                    {{$toLocal(coin.credit,$decimal[tradeFrom])}}
                </span>
            </div>
            <div class="info">
                <span class="wallet-subtitle">
                    قابل برداشت
                </span>
                <span class="number-font wallet-subtitle">
                    {{$toLocal(coin.credit,$decimal[tradeFrom])}}
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    precision
} from "@/library/reuseableFunction";
export default {
    name: 'WalletBalance',
    components: {

    },
    data() {
        return {
            iformations: [{
                title1: "تومان",
                number1: 0,
                title2: "قابل برداشت",
                number2: 0

            }, ],
            coin: '',
            base: '',
            change: 0
        }
    },
    computed: {
        tradeFrom,
        tradeTo,
        precision
    },
    methods: {
        async getWalletData() {
            const res = await this.$axios(`/wallets/customer-addresses?walletType=P2P`)
            this.coin = res[this.tradeFrom]
            this.base = res[this.tradeTo]
            this.change
        },
    },
    mounted() {
        if(this.state.userInfo.authorized) {
            this.getWalletData()
        }
        
    }
}
</script>

<style lang="scss" scoped>
.wallet-balance {
    // max-width: 234px;
    // flex-grow: 1;
    width: 260px;
    max-height: 311px;
    // background: #4F4F4F;
    border-radius: 8px;
    height: 100%;

    .wallet-container {
        background: var(--background-child);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        padding: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 33px;
        border: 2px solid var(--border-color);
    }
}

.info-container {
    display: flex;
    flex-direction: column;

    .info {
        display: flex;
        justify-content: space-between;
    }
}

.title-container {
    display: flex;
    justify-content: space-between;
}

.wallet-title {
    font-weight: bold;
    font-size: 14px;
    color: var(--font-strong);
}

.observe {
    color: rgba(255, 185, 4, 1);
    font-weight: normal;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 185, 4, 1);
}

.wallet-subtitle {
    font-weight: normal;
    font-size: 12px;
    color: #BDBDBD;
}
@media only screen and (max-width: 600px) {
   .wallet-balance  {
        width: 100%;
    }
}
</style>
