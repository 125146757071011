<template>
<transition>
    <div class="modal-backdrop" @click.self="$emit('close')">
        <div class="modal">
            <img @click="closeModale" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <div class="modal-container">
                <span class="modal-title">
                    <span v-if="type == 'BUY'">
                        خرید
                    </span>
                    <span v-else>
                        فروش
                    </span>
                </span>
                <!-- <span class="describe">
              ارز فروشی خود را به آدرس کیف پول زیر ارسال کنید تا مبلغ آن به کیف پول تومانی شما واریز شود
            </span> -->
                <!-- <img class="qr-img" src="@/assets/Profile/qr.png"> -->
                <!-- <lable>
              <input >
            </lable> -->
                <!-- <label for="">
              <input type="text">
            </label> -->
                <!-- <span class="warning">
              این کیف پول فقط برای فروش بیت‌کوین به بی بیتکس است. به هیچ عنوان ارز دیگری به این کیف پول ارسال نکنید.
            </span> -->
                <div class="amounts">
                    <div class="crypto-amount">
                        <span>
                            رمزارز
                        </span>
                        <span class="price ">
                            {{$coins[tradeFrom].persianName}}
                        </span>
                    </div>
                    <div class="crypto-amount">
                        <span>
                            قیمت واحد
                        </span>
                        <span class="price ">
                            {{unitPrice}}
                        </span>
                    </div>
                    <div class="crypto-amount">
                        <span>
                            مقدار رمزارز
                        </span>
                        <span class="price ">
                            {{amount}}
                        </span>
                    </div>
                    <div class="price-amount">
                        <span v-if="type == 'BUY'">
                            پرداختی شما
                        </span>
                        <span v-else>
                            دریافتی شما
                        </span>
                        <span class="price ">
                            {{totalPrice}}
                        </span>
                    </div>

                    <div class="crypto-amount" v-if="date">
                        <span>
                            ناریخ انقضا
                        </span>
                        <span class="price ">
                            {{$G2J(date)}}
                        </span>
                    </div>
                </div>
                <!-- <label for="">
              <input type="text">
            </label> -->
                <!-- <lable>
              <input>
            </lable> -->
            </div>
            <button class="add-btn" @click.prevent="accept">
                ثبت
            </button>
        </div>
    </div>
</transition>
</template>

<script>
import {
    tradeTo,
    tradeFrom
} from "@/library/reuseableFunction";
export default {
    name: 'ExchangeSellModal',
    components: {

    },
    props: [
        'unitPrice', 'totalPrice', 'amount', 'date', 'type', 'limit'
    ],
    data() {
        return {

            open: true,

        }
    },
    computed: {
        tradeTo,
        tradeFrom,
    },
    methods: {
        closeModale() {
            this.$emit('close')
        },
        OpenSuccess() {
            this.$emit('close')
        },
        async accept() {
           
            let orderDetail;
            let marketType = this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo

            if (this.limit) {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `LIMITED_${this.type}`,
                    expiresAt: this.date || null,
                    unitPrice: this.$S2N(this.unitPrice),
                    wholePrice: this.$S2N(this.totalPrice),
                }
            } else {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `MARKET_${this.type}`,
                }
            }
            try {
                 this.state.loading = true;
                await this.$axios.post(`/orders/${this.type.toLowerCase()}`, orderDetail, {
                    params: {
                        force: true
                    }
                })
                this.loading = false
                this.$emit('close')
                 this.state.loading = false;
                this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
                ++this.state.orderChange
            } 
            catch (error) {
                this.loading = false
                this.$emit('close')
                if (error.response.data.message) {
                    //  === "the amount of order is bigger than market liquidity"
                    // await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
                    this.$error('', error.response.data.message)
                }
            }
             this.state.loading = false;
        },
    },

}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 200px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
}

.close-icon {
    position: absolute;
    cursor: pointer;
}

.modal-title {
    font-weight: bold;
    font-size: 18px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.warning {
    color: #E43700;
    font-weight: normal;
    font-size: 12px;
}

.qr-img {
    width: 114px;
    height: 114px;
    margin: 0 auto;
}

.modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

button {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.amounts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.7;
    color: #F2F2F2;
    font-weight: 300;
    font-size: 16px;
    flex-direction: column;
    margin-bottom: 20px;
    row-gap: 10px;
}

.price {
    font-weight: bold;
    font-size: 12px;
}

.crypto-amount,
.price-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
</style>
