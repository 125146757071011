<template>
<div class="form">
    <form class="inputs-container" @submit.prevent="send">
        <label for="">
            <input type="email" placeholder="آدرس ایمیل" v-model="data.email">
            <p>{{emailError}}</p>
        </label>
        <label for="">
            <input :type="type" placeholder="رمز عبور" v-model="data.password" :errortext="passError">
            <inline-svg class="pass-img" :class="{'show-pass-img' : type=='text'}" :src="require('../../assets/Home/showPass.svg')" alt="" @click="showPass"></inline-svg>
            <span class="line" :class="{'line-hide' :  type=='text'}"></span>
            <p>{{passError}}</p>
        </label>
        <a href="/twoFA-email" @click.prevent="$router.push('/forget-password')">
            <span class="forget-p">
                فراموشی رمز عبور؟
            </span>
        </a>
        <button type="submit" class="login-btn" :disabled="!data.email || !data.password">
            ورود
        </button>
    </form>
    <div class="buttons-container">
        <span class="or">
            یا
        </span>
        <button class="google-btn" @click="googleSso">
            <img src="@/assets/Home/google.png" alt="">
            ورود با حساب گوگل
        </button>
        <span class="to-sub">
            حساب کاربری ندارید؟
            <span class="sub-link" @click="$router.push('/signup')">
                ثبت نام کنید
            </span>
        </span>
    </div>
</div>
</template>

<script>
const goto = {
    EMAIL: '/twoFA-email',
    SMS: '/two-step-sms',
    GOOGLE_AUTHENTICATOR: '/two-step-google-auth',
}
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'LoginForm',
    components: {
        InlineSvg
    },
    data() {
        return {
            type: 'password',
            data: {
                email: '',
                password: '',
                osType: '',
                deviceType: '',
                browser: '',
            },
            show: false,
            emailError: '',
            passError: ''
        }
    },
    methods: {
        googleSso() {
            window.location.href = this.$google_sso
        },
        showPass() {
            if (this.type === "password") {
                this.type = 'text'
            } else {
                this.type = 'password'
            }
        },
        async send() {
            await this.checkEmail()
            // this.state.loading = true
            const res = await this.$axios.post('/users/login', this.data)
            if (res.message === 'login successful, otp is required') {

                this.$cookies.set('username', this.data.email)
                this.state.userInfo.email = this.data.email
                this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                this.state.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])

            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/useraccount')
            }

        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            }
        },
        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    //   row-gap: 124px;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
    width: 100%;
}

label {
    width: 100%;
    position: relative;
}

input {
    background: rgba(105, 105, 105, 0.38);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    color: #FEFEFE;
    box-sizing: border-box;
    padding: 0 10px;
}

input::placeholder {
    color: #FEFEFE;
}

.pass-img {
    cursor: pointer;
    position: absolute;
    left: 12px;
    top: 12px;
}

.forget-p {
    color: #F2F2F2;
    font-size: 12px;
    cursor: pointer;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .or {
        margin: 12px;
        color: #F2F2F2;
    }
}

.to-sub {
    margin-top: 20px;
    font-size: 12px;
    color: #FEFEFE;

    .sub-link {
        color: #FFC04F;
        border-bottom: 1px solid #FFC04F;
        cursor: pointer;
    }
}

.login-btn {
    border: none;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 44px;
    font-size: 14px;
    color: #FCFCFC;
    width: 100%;
}

.google-btn {
    width: 100%;
    position: relative;
    height: 44px;
    background: rgba(105, 105, 105, 0.38);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    color: #FEFEFE;

    img {
        position: absolute;
        right: 10px;
        top: 6px;
    }
}
</style>
