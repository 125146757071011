<template>
<div class="otc">
    <!-- <OTCModal v-if="modal" @closeModaleEmit="closeModal" unitPrice="$S2N(price)/$S2N(amount)" :totalPrice="price" :amount="amount" type="BUY" :date="null" :related-coin="$coinLabel[fromunit[0]]" :limit="false" @ok="accept" /> -->
    <ExchangeModal :data="data" v-if="exModal" @close="exModal = false" :beebitexWallet="beebitexWallet" @submit="sendData" :unit="toman" />
    <SuccessExchange :data="temptData" v-if="success" @close="success = false;tomanTetherWallet();getWallets();" :toman="toman" :buy="buy"/>
    <div class="form">
        <div class="form-title">
            <span class="main-title">
                معامله سریع
            </span>
            <span class="subTtile">
                سریع ترین راه تبدیل ارز شما
            </span>
        </div>
        <div class="form-opration">
            <div class="pay-container">
                <!-- <div class="pay-title">
                    <img src="@/assets/Profile/sellBuy.svg" alt="">
                    <span>1 &nbsp;{{$coins[Object.keys(walletBalance)[0]].code}} </span>
                    =
                    {{$toLocal(data.unitPrice)}}
                </div> -->
                <div class="pay-title">
                    <!-- <img src="@/assets/Profile/sellBuy.svg" alt=""> -->
                    <div>
                        <span v-if="BuywalletBalance">{{$toLocal(BuywalletBalance.credit,$decimal[toman ? 'TOMAN' : 'TETHER'])}}</span>&nbsp;
                        {{$coins[toman ? 'TOMAN' : 'TETHER'].persianName}}
                    </div>
                </div>
                <div class="pay">
                    <label for="">
                        <input type="text" :placeholder="!buy ? 'دریافت میکنید' : 'می پردازید'" @input="inputsFun('totalPrice')" v-model="data.totalPrice">

                        <div class="toman-drop">
                            <div class="toman-drop-name">
                                <div class="unit" @click="unitFun">
                                    <div class="unit-title">
                                        <span v-if="toman">
                                            <img class="unit-icons" src="/coins/TOMAN.png" alt="">
                                            تومان
                                        </span>
                                        <span v-else>
                                            <img class="unit-icons" src="/coins/TETHER.png" alt="">
                                            تتر
                                        </span>

                                    </div>
                                    <div class="unit-icons">
                                        <img src="../../../assets/Home/Vector.svg" alt="" class="up-index">
                                        <img src="../../../assets/Home/Vector.svg" alt="" class="down-index">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </label>
                </div>
                <div class="pay-footer">
                    <span>حجم قابل معامله</span>
                    <span>{{$toLocal(coins.find(a => a.coin==(toman ? 'TOMAN' : 'TETHER')).maxAmountExchange)}}</span>
                    <span v-if="toman">تومان</span>
                    <span v-else>تتر</span>
                </div>
            </div>
            <img src="@/assets/Profile/Convert.svg" alt="" class="convert_img" @click="buy=!buy;beforeLoop()" :class="{'rotate' : buy}">
            <div class="gain-container">
                <div class="gain">
                    <div class="pay-title">
                        <img src="@/assets/Profile/sellBuy.svg" alt="">
                        <div>
                            <span>
                                {{$coins[coin].persianName}}
                            </span>
                            <span>
                                {{$toLocal(credit,$decimal[coin])}}
                            </span>
                        </div>
                    </div>
                    <label for="">
                        <input type="text" :placeholder="!buy ? 'می پردازید' : 'دریافت میکنید  '" @input="inputsFun('amount')" v-model="data.amount">
                        <p class="error-p">
                            {{sellAmount}}
                        </p>
                        <div class="crypto-drop" @click="drop=!drop">
                            <div class="drop-name">
                                <img class="drop-img" :src="'/coins/'+ coin + '.png'" :alt="coin">
                                <span>
                                    {{$coins[coin].persianName}}
                                </span>
                            </div>
                            <img src="@/assets/Home/Vector.svg" alt="">
                            <CryptoDropDown v-if="drop" @selectCoin='selectedCoin' />
                        </div>
                    </label>
                </div>
                <div class="gain-footer">
                    <span>حجم قابل معامله</span>
                    <span>{{$toLocal(coins.find(a => a.coin==coin).maxAmountExchange)}}</span>
                    <span>{{coins.find(a => a.coin==coin).code}}</span>
                </div>
            </div>
        </div>
        <transition name="fade">
        <p class="error-p" v-if="sellReadOnly">
            {{sellReadOnly}}
        </p>
        </transition>
        <button class="order-btn" @click="checkModal" :disabled="!!sellReadOnly">
            <!-- :disabled="disable" -->
            ثبت سفارش {{buy ? 'خرید':'فروش'}}
        </button>
    </div>
</div>
</template>

<script>
// import CryptoDropDown from '../../Public/CryptoDropDown.vue'
// import OTCModal from './OTCModal.vue'
import ExchangeModal from '../Exchange/ExchangeModal.vue'
import CryptoDropDown from '../../Public/CryptoDropDown.vue'
import SuccessExchange from '../Exchange/SuccessExchange.vue'
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'OTCExchange',
    components: {
        CryptoDropDown,
        ExchangeModal,
        SuccessExchange
        // OTCModal
    },
    data() {
        return {
            temptData:{},
            isTyping: false,
            disable: true,
            loop: undefined,
            sellAmount: '',
            buy: true,
            coin: 'BITCOIN',
            credit: '',
            walletBalance: {},
            loading: false,
            prices: "",
            beebitexWallet: true,
            netDrop: false,
            selected_net: "",
            nets: [],
            coins: [],
            txIdIsNeeded: false,
            totalPrice: '',
            exModal: false,
            success: false,
            BuywalletBalance: '',
            minAmountExchange: "",
            maxAmountExchange: "",
            data: {
                marketType: "TRON_TOMAN",
                amount: null,
                sourceCoin: "TOMAN",
                destinationCoin: "TRON",
                orderType: "EXCHANGE_BUY",
                // tokenType: "",
                // destinationWallet: "",
                unitPrice: null,
                totalPrice: null
            },
            placeholderCrypto: 'دریافت میکند',
            placeholderToman: 'می پردازد',
            amount: '',
            price: '',
            drop: false,
            modal: false,
            alive: true,
            toman: true,
            wallet: [{}],
            fromAmount: true,
            calculating: false,

        }
    },
    computed: {
        sellReadOnly() {
            let error = ''
            if (!this.$S2N(this.data.amount) && this.$S2N(this.data.totalPrice)) {
                error = 'مقدار ارز اجباری است'
                // this.disableSell = true
            } else if (this.$S2N(this.data.amount) < this.minAmountExchange) {
                error = 'حداقل مقدار رعایت نشده'
                // this.disableSell = true
            } else if ((this.buy && (this.$S2N(this.data.totalPrice) > this.BuywalletBalance.credit)) || (!this.buy && this.$S2N(this.data.amount) > this.walletBalance.credit)) {
                error = 'مقدار بیش از موجودی شماست'
                // this.sellAmount = 'مقدار بیش از موجودی شماست'
                // this.disableSell = true
            } else if (this.$S2N(this.data.amount) > this.maxAmountExchange) {
                error = 'حداکثر مقدار رعایت نشده'
                // this.sellAmount = 'حداکثر مقدار رعایت نشده'
                // this.disableSell = true
            } else if (this.calculating) {
                error = 'لطفا صبر کنید ...'
            }
            return error
            //  else {
            //     this.disableSell = false
            //     this.sellAmount = ''
            // }

        },
        buyReadOnly() {
            let error = ''
            if (!this.$A2N(this.data.amount)) {
                error = 'مقدار ارز اجباری است'
            } else if (this.$A2N(this.data.amount) < this.minAmountExchange) {
                error = 'حداقل مقدار رعایت نشده'
            } else if (this.$A2N(this.data.totalPrice) > this.BuywalletBalance.credit) {
                error = 'مقدار بیش از موجودی شماست'
            } else if (this.$A2N(this.data.amount) > this.maxAmountExchange) {
                error = 'حداکثر مقدار رعایت نشده'
            } else if (!this.beebitexWallet) {
                if (this.data.destinationWallet == '') {
                    error = ' آدرس کیف پول اجباری است '
                } else if (this.data.tokenType == '') {
                    error = 'انخاب شبگه اجباری است'

                }
            }
            return error
        },
    },
    methods: {
        // sellReadOnly() {
        //     if (this.buy) {
        //         if (!this.$A2N(this.data.amount)) {
        //             this.disable = true
        //         } else if (this.$A2N(this.data.amount) < this.minAmountExchange) {
        //             this.sellAmount = 'حداقل مقدار رعایت نشده'
        //             this.disable = true
        //         } else if (this.$A2N(this.data.totalPrice) > Object.values(this.BuywalletBalance)[0].credit) {

        //             this.sellAmount = 'مقدار بیش از موجودی شماست'
        //             this.disable = true
        //         } else if (this.data.amount > this.maxAmountExchange) {
        //             this.sellAmount = 'حداکثر مقدار رعایت نشده'
        //             this.disable = true
        //         } else if (this.data.amount == '' || this.data.totalPrice == '') {
        //             this.disable = false
        //             this.sellAmount = ''
        //         } else {
        //             this.disable = false
        //             this.sellAmount = ''
        //         }
        //     } else {
        //         if (!this.$A2N(this.data.amount)) {
        //             this.disable = true
        //         } else if (this.$A2N(this.data.amount) < this.minAmountExchange) {
        //             this.sellAmount = 'حداقل مقدار رعایت نشده'
        //             this.disable = true
        //         } else if (this.$S2N(this.data.amount) > Object.values(this.walletBalance)[0].credit) {
        //             this.sellAmount = 'مقدار بیش از موجودی شماست'
        //             this.disable = true
        //         } else if (this.data.amount > this.maxAmountExchange) {
        //             this.sellAmount = 'حداکثر مقدار رعایت نشده'
        //             this.disable = true
        //         } else {
        //             this.disable = false
        //             this.sellAmount = ''
        //         }
        //     }
        // },
        async unitFun() {
            this.data.totalPrice = ''
            if (this.loop) {
                this.loop.stop()
            }
            this.toman = !this.toman;
            // $emit('changeUnit',toman);
            // calcTotalPrice();calcAmount();
            await this.tomanTetherWallet()
            this.beforeLoop()
        },
        async inputsFun(e) {
            this.calculating = true
            if (e === 'amount') {
                this.data.amount = this.$toLocal(this.data.amount, this.$decimal[this.coin])
            } else {
                this.data.totalPrice = this.$toLocal(this.data.totalPrice, this.$decimal[this.toman ? 'TOMAN' : 'TETHER'])
            }

            this.fromAmount = e === 'amount'
            if (this.loop) {
                this.loop.stop()
            }
            const temp = this.data[e]
            await this.$sleep(500)
            if (temp === this.data[e]) {
                this.beforeLoop()
            }

        },
        // totalPriceFun() {
        //     this.calcAmount()+
        //     this.sellReadOnly()
        //     this.data.totalPrice = this.$toLocal(this.data.totalPrice, this.$decimal[this.toman ? 'TOMAN' : 'TETHER'])

        // },
        // amountFun() {

        //     this.calcTotalPrice()
        //     this.data.amount = this.$toLocal(this.data.amount)
        //     this.sellReadOnly()
        // },
        checkModal() {
            if (this.buy) {
                this.data.marketType = this.coin + '_' + (this.toman ? 'TOMAN' : 'TETHER')
                this.data.destinationCoin = this.coin
                this.data.orderType = 'EXCHANGE_BUY'
                this.exModal = true
            } else {
                this.data.marketType = this.coin + '_' + (this.toman ? 'TOMAN' : 'TETHER')
                this.data.destinationCoin = this.coin
                this.data.orderType = 'EXCHANGE_SELL'
                this.exModal = true
            }
        },
        async sendData() {
             this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.temptData.amount)
            this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice)
             this.temptData.unitPrice = this.$S2N(this.temptData.unitPrice)
            this.exModal = false
            if (this.buy) {

                    this.state.loading = true
                    await this.$axios.post(
                        '/exchange/buy', this.temptData
                    )
                    this.success = true

            } else {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                    this.state.loading = true
                    await this.$axios.post(
                        '/exchange/sell', this.temptData
                    )
                    this.success = true
               
                this.state.loading = false
            }

        },
        async getWallets() {
            this.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + this.coin)
            if (res) {
                this.walletBalance = res[this.coin]
                this.credit = this.walletBalance.credit
            }
            this.loading = false
        },
        async getPrice() {
            const res = await this.$axios.get('coins/exchange-price/' + (this.toman ? 'usd' : 'usdt') + '?includeWeeklyChange=false&destCoinSymbol=' + this.coin)
            this.prices = res
            this.data.unitPrice = this.prices.find(a => a.destCoinSymbol == this.coin)[this.buy ? 'buyPrice' : 'sellPrice']
            if (this.fromAmount) {
                this.calcTotalPrice()
            } else {
                this.calcAmount()
            }
            this.calculating = false
        },
        async tomanTetherWallet() {
            // this.loading = true
            const [res2, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + (this.toman ? 'TOMAN' : 'TETHER'))
            if (res2) {
                this.BuywalletBalance = res2[this.toman ? 'TOMAN' : 'TETHER']
            }
            // this.loading = false
        },
        calcTotalPrice() {
            this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice), this.$decimal[this.toman ? 'TOMAN' : 'TETHER'])
        },
        calcAmount() {
            this.data.amount = this.$toLocal(this.$S2N(this.data.totalPrice) / this.$S2N(this.data.unitPrice), this.$decimal[this.coin])
        },
        async getCoins() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content

        },

        titlesclick() {
            if (this.buyTtile) {
                return true
            } else {
                this.buyTtile = true
                this.sellTitle = false
            }
        },
        async selectedCoin(value) {
            this.data.amount = 0
            this.coin = value.destCoinSymbol
            await this.getWallets()
            // this.getNets()
            this.beforeLoop()
        },
        MinMaxAmount() {
            this.minAmountExchange = this.coins.find(a => a.coin == this.coin).minAmountExchange
            this.maxAmountExchange = this.coins.find(a => a.coin == this.coin).maxAmountExchange
        },
        beforeLoop() {
            this.calculating = true
            if (this.loop) {
                this.loop.stop()
            }
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }

    },
    async mounted() {

        await this.getWallets()
        // await this.getPrice()
        await this.getCoins()
        await this.tomanTetherWallet()
        await this.MinMaxAmount()
        this.beforeLoop()
    }

}
</script>

<style lang="scss" scoped>
.otc {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-top: 24px;

    .form {
        background: #001E3D;
        border-radius: 8px;
        max-width: 464px;
        height: 494px;
        padding: 24px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.order-btn {
    width: 100%;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #E0E0E0;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
}

.form-title {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;

    .main-title {
        font-weight: bold;
        font-size: 24px;
        color: #FFFFFF;
        text-align: center;
    }

    .subTtile {
        font-weight: normal;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
    }
}

.form-opration {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
}

.pay-container,
.gain-container {
    width: 100%;
}

.toman-drop {
    position: absolute;
    background: #56626A;
    border-radius: 4px;
    height: 44px;
    width: 133px;
    top: -9px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    //padding: 0 8px;
    .toman-drop-name {
        display: flex;
        align-items: center;
        column-gap: 12px;
        font-weight: normal;
        font-size: 14px;
        color: #FEFEFE;
        position: relative;
        width: 100%;
        height: 100%;

        select {
            width: 100%;
            height: 100%;
            background: #56626A;
            color: #FEFEFE;
        }

        .for-drop {
            cursor: pointer;
        }
    }
}

.drop-img {
    width: 24px;
    height: 24px;
}

.gain-footer,
.pay-title,
.pay-footer {
    font-weight: normal;
    font-size: 12px;
    color: #E2E2E2;
    margin: 8px 0;
    justify-content: flex-end;
    display: flex;
    column-gap: 8px;
}

.wallet-balance {
    border-bottom: 1px solid #E2E2E2;
}

.convert_img {
    -webkit-transition: -webkit-transform .5s ease-in-out;
    -ms-transition: -ms-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
}

.rotate {
    transform: rotate(180deg);
}

.unit {
    position: absolute;
    background: rgba(159, 159, 159, 0.35);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    left: 0;
    // top: 8px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #FEFEFE;
}

.up-index {
    transform: rotate(180deg);

    path {
        fill: red;
    }
}

.unit-icons {
    display: flex;
    flex-direction: column;
    width: 20px;
    align-items: center;
    row-gap: 10px;
}

.unit-title {
    span {
        display: flex;
        column-gap: 5px;
    }
}

.crypto-drop {
    position: absolute;
    background: rgba(159, 159, 159, 0.35);
    border-radius: 4px;
    height: 42px;
    width: 135px;
    left: 0;
    top: -8px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex-grow: 1;

    .drop-img {
        width: 24px;
        height: 24px;
    }

    .drop-name {
        display: flex;
        column-gap: 7px;
        align-items: center;
        font-weight: normal;
        font-size: 12px;
        color: #FEFEFE;
    }
}
</style>
