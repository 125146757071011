<template>
<div class="wealth">
    <!-- <AppexChart /> -->

    <DepositToman v-if="open==='DepositToman'" @closeModaleEmit="closeModal" />
    <WithdrawalToman v-if="open==='withdrawalToman'" @closeModaleEmit="closeModal" />
    <div class="wealth-content">
        <div class="weaith-statistics">
            <div class="balance">
                <template v-for="(content,index) in contents">
                    <div class="wallet" v-if="content[0]=='TOMAN'" :key="index">
                        <span>
                            موجودی کیف پول تومانی
                        </span>
                        <span class="number-font">
                            {{credit(content)}}<span class="unit"> &nbsp; تومان</span>
                        </span>
                    </div>
                </template>
            </div>
            <div class="btn-container">
                <button class="withdrawal depositeWith-btn" @click="open='withdrawalToman'">
                    برداشت
                </button>
                <button class="deposit depositeWith-btn" @click="open='DepositToman'">
                    واریز
                </button>
            </div>
        </div>
        <div class="market-exchange">
            <div class="wealth-crypto">
                <p class="market-exchange-title">
                    بازار
                </p>
                <apexchart type="donut" width="250" height="280" :options="chartOptions" :series="series" :key="series.length + 'P2P' + chartOptions.labels.length + change" />
                <span class="table-title">
                    رمز ارز های من
                </span>
                <div class="table-container">
                    <perfect-scrollbar>
                        <table>
                            <template v-for="(content , index) in contents">
                                <tr class="p2p-tr" v-if="content[0]!='TOMAN'" :key="index"  @click="goto($coins[content[0]].coin)">
                                    <td class="img-name">
                                        <img class="wealth_coin" :src="'/coins/'+ content[0] + '.png'" alt="">
                                        <span>{{$coins[content[0]].persianName}}</span>
                                    </td>
                                    <td class="number-font">
                                        {{credit(content)}} <span> {{$coins[content[0]].code}} </span>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </perfect-scrollbar>
                </div>
            </div>
            <div class="wealth-crypto">
                <p class="market-exchange-title">
                    صرافی
                </p>
                <apexchart type="donut" width="250" height="280" :options="chartOptionsExchange" :series="seriesExchange" :key="series.length + 'EXCHANGE' + chartOptions.labels.length + change" />
                <span class="table-title">
                    رمز ارز های من
                </span>
                <div class="table-container">
                    <perfect-scrollbar>
                        <table>
                            <template v-for="(content , index) in contentsExchange">
                                <tr class="exchange-tr" v-if="content[0]!='TOMAN'" :key="index" @click="gotoExchange(content[0])">
                                    <td class="img-name">
                                        <img class="wealth_coin" :src="'/coins/'+ content[0] + '.png'" alt="">
                                        <span>{{$coins[content[0]].persianName}}</span>
                                    </td>
                                    <td class="number-font">
                                        {{credit(content)}} <span> {{$coins[content[0]].code}} </span>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DepositToman from '../../Wallets/WalletsModal/DepositToman.vue';
import WithdrawalToman from '../../Wallets/WalletsModal/WithdrawalToman.vue'
export default {
    name: 'Wealth',
    components: {
        DepositToman,
        WithdrawalToman,
    },
    data() {
        return {
            balance: null,
            max: null,
            open: '',
            contents: [],
            contentsExchange: [],
            chartData: '',
            chartDataExchange: '',
            series: [0],
            seriesExchange: [0],
            change: 0,
            wallets: {},
            walletsExchange: {},
            chartOptions: {
                chart: {
                    width: '50px',
                    fontFamily: 'Yekan',
                },
                colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
                theme: {
                    mode: 'light',
                    palette: 'palette2'
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                labels: [],
                dataLabels: {
                    style: {
                        fontFamily: 'Yekan-en'
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                            size: '65%',
                            background: '#001E3D',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Yekan',
                                    fontWeight: 600,
                                    color: '#F2F2F2',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Yekan-en',
                                    fontWeight: 400,
                                    color: '#F2F2F2',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'Yekan',
                                    fontWeight: 600,
                                    color: '#F2F2F2',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                }
            }, chartOptionsExchange: {
                chart: {
                    width: '50px',
                    fontFamily: 'Yekan',
                },
                colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
                theme: {
                    mode: 'light',
                    palette: 'palette2'
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                labels: [],
                dataLabels: {
                    style: {
                        fontFamily: 'Yekan-en'
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                            size: '65%',
                            background: '#001E3D',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Yekan',
                                    fontWeight: 600,
                                    color: '#F2F2F2',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Yekan-en',
                                    fontWeight: 400,
                                    color: '#F2F2F2',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'Yekan',
                                    fontWeight: 600,
                                    color: '#F2F2F2',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                }
            }, 
        }
    },
    computed: {
        credit() {
            return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].credit.toFixed(0) : e[1].credit) || 0
        }
    },
    methods: {
        closeModal(value) {
            this.open = value
        },
        goto(e) {
            this.$router.push('trade/' + 'TOMAN' + `/${e}`)
        },
        gotoExchange(e) {

            this.$router.push('/profile/exchange?from='+e)
        },
        async getWalletData() {
            const res = await this.$axios(`/wallets/customer-addresses?walletType=P2P`)
            let entireRes = Object.entries(res)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            this.contents = entireRes
            this.chartData = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
            this.wallets = entireRes
        },
        chart() {
            let index = 0
            this.wallets.forEach((e) => {
                    this.chartOptions.labels[index] = {
                        'name': this.$coins[e[0]].persianName,
                        'value': e[1].credit || 0
                    }
                    this.series[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
                    index++
                })
                ++this.change
        },
        async getWalletDataExchange() {
            const res = await this.$axios(`/wallets/customer-addresses?walletType=EXCHANGE`)
            let entireRes = Object.entries(res)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            this.contentsExchange = entireRes
            this.chartDataExchange = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
            this.walletsExchange = entireRes
        },
        chartExchange() {
            let index = 0
            this.walletsExchange.forEach((e) => {
                    this.chartOptionsExchange.labels[index] = {
                        'name': this.$coins[e[0]].persianName,
                        'value': e[1].credit || 0
                    }
                    this.seriesExchange[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
                    index++
                })
                ++this.change
        },
        routing(e) {
            if (this.$route.params.tradeTo !== e)
                this.$router.push('/dashboard/' + this.$route.params.tradeTo + '/' + e)
        }
    },
    async mounted() {
        this.state.loading = true
        await this.getWalletData()
        await this.getWalletDataExchange()
        this.chart()
        this.chartExchange()
        this.state.loading = false
    }
}
</script>

<style lang="scss" scoped>
.img-container {
    width: 23px;
    height: 23px;
    position: absolute;
    right: 0;
}

.wealth {
    width: 100%;
    min-height: 351px;
    background: #001E3D;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px 21px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 55px;
    row-gap: 20px;
}

.wealth-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 30px;

    .weaith-statistics,
    .wealth-crypto {
        display: flex;
        flex-wrap: wrap;
    }

    .wealth-crypto {
        flex-direction: column;
        flex-grow: 3;
        align-items: center;
    }

    .weaith-statistics {
        flex-grow: 1;
        row-gap: 30px;

        .balance {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            color: #FFFFFF;
            row-gap: 16px;

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .btn-container {
            display: flex;
            flex-grow: 1;
            column-gap: 8px;
            justify-content: flex-end;

            .withdrawal,
            .deposit {
                width: 136px;
                height: 40px;
                font-weight: bold;
                font-size: 14px;
                color: #FCFCFC;
                border-radius: 6px;
            }

            .withdrawal {
                background: linear-gradient(270deg, rgba(255, 47, 1, 0.97) 0%, rgba(255, 61, 0, 0.979333) 32.6%, rgba(255, 92, 0, 0.984313) 64.37%, #FF3D00 100%);
            }

            .deposit {
                background: linear-gradient(90deg, #9DE700 0%, #5AB400 51.76%, #91D600 101.82%);
            }
        }
    }
}

.wallet {
    font-weight: 500;
    font-size: 20px;
}

.unit {
    font-weight: normal;
    font-size: 16px;
}

.table-title {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin: 26px 0;
}

table img {
    width: 13px;
    height: 13px;
}

.table-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    height: 140px;
    overflow: scroll;
    width: 100%;

    table {
        width: 100%;
        min-width: 200px;
        flex-grow: 1;
        // max-width: 350px;

        tr {
            height: 36px;
            font-weight: normal;
            font-size: 14px;
            color: #FFFFFF;
            position: relative;
        }
    }
}

.img-name {
    // position: relative;
    padding-right: 40px;

    img {
        vertical-align: inherit;
        margin-left: 16px;
        position: absolute;
        right: 5px;
        top: 12px;
    }
}

.table-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
    display: none;
}

.wealth_coin {
    width: 25px;
    height: 25px;
}

.market-exchange {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;

    .market-exchange-title {
        color: white;
        font-size: 20px;
    }
}

.p2p-tr,
.exchange-tr {
    cursor: pointer;
}

.p2p-tr:hover,
.exchange-tr:hover {
    background-color: rgba(253, 215, 91, 0.3) !important;
}
</style>
