 import Vue  from "vue";
// import Vuex from "vuex";
Vue.use(require('vue-cookies'))
// Vue.use(store);
 
// export default new Vuex.Store({
//  state: {
//      text:'kjjhjhmhjgv'
//  },
//  getters: {},
//  mutations: {},
//  actions: {}
// });
// const store = {};
// export  default Vue.observable({
//     fontColor:'#FEFEFE'
//   });
const that = Vue.prototype
const state =  Vue.observable({
    exchangeBuyPrice:0,
    exchangeSellPrice:0,
      fontColor:'red',
      theme:'dark',
      light:false,
      coins : false,
      orderChange:0,
      token: localStorage.token || Vue.$cookies.get('--abcd14589'),
      // token:'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuaW1hMjE0Mi5wMzBAZ21haWwuY29tIiwiaWF0IjoxNjQ0MjM2OTUyLCJleHAiOjE2NDYzODQ0MzV9.7_W6sXZ8KMkF6hhSQkBEQ_avSN1PgAE2fFmC88mo3so',
    loading: false,
    gotten: false,
    loop: true,
    reload:0,
    progress:0,
    towStepAuthenticationType:'',
    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        remember: false,
        towStepAuthenticationType: '',
        authenticationType: '',
        authorized: false,
        pending: false,
        supervisor: false,
        userAccountLevel: '',
        totalTradesVolume: 0,
        fee: 0.4,
    },

    shopInfo: {
        minSell: 0,
        maxBuy: 0,
        lastPrice: 0,
    },

    buyAmount:0,
    buyPrice:0,
    sellAmount:0,
    sellPrice:0
    })
    that.state = state
export default state
