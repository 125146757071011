<template>
<div class="currencyTable">
    <TheTableTitle @passcurrency="getCurrency" :items="items" />
    <div class="table-container">
        <table class="multi_color">
            <tr class="tr-heade">
                <td v-for="(heade , index) in heade" :key="index" :class=heade.class>
                    {{heade.title}}
                </td>
            </tr>
            <tr class="tr-body" v-for="(coin , index) in coins" :key="index" @click="goto($coin2Snake[coin.relatedCoin])">
                <td class="name">
                    <img class="crypto-img" :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" :alt="coin.relatedCoin">
                    <span>{{$coinLabel[coin.relatedCoin]}}</span>
                </td>
                <td class="onlinePrice number-font">
                    <span class="number-font">{{$toLocal(coin.lastPrice,$decimal[items[0].class=='active' ? 'TOMAN': 'TETHER'])}}</span>
                </td>
                <td class="change number-font" :class="{'positive-change' : coin.last24HourChange > 0 , 'negative-change' : coin.last24HourChange < 0}">
                    %
                    {{Math.abs(coin.last24HourChange).toFixed(2)}}
                    <span v-if=" coin.last24HourChange < 0"> - </span>
                    <span v-if="coin.last24HourChange > 0"> + </span>
                </td>
                <td class="chart">
                    <TrendChart :trend='coin.lastWeekData' />
                </td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
import TheTableTitle from '../Public/TheTableTitle.vue'
import TrendChart from './TrendChart.vue'
export default {
    name: 'CurrencyTable',
    components: {
        TheTableTitle,
        TrendChart
    },
    data() {
        return {
            tableTitle: [

            ],
            items: [{
                    title: "تومان",
                    class: "active"
                },
                {
                    title: "تتر",
                    class: "deActive"
                }
            ],
            heade: [{
                    title: "رمزارز",
                    class: "name"
                },
                {
                    title: "قیمت لحظه ای",
                    class: "onlinePrice"
                },
                {
                    title: "تغییرات",
                    class: "change"
                },
                //  {
                //    title:"قیمت خرید از سایت",
                //    class:"buyPrice"
                //  },
                //  {
                //    title:"قیمت فروش به سایت",
                //    class:"sellPrice"
                //  },
                {
                    title: "نمودار",
                    class: "chart"
                }
            ],
            coins: [],
            currency: "toman",
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +

                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +

                '&market_type=TETHER_TOMAN',
            TetherCoin: 'market_type=BITCOIN_TETHER' +
                '&market_type=BITCOIN_CASH_TETHER' +
                '&market_type=ETHEREUM_TETHER' +
                '&market_type=LITE_COIN_TETHER' +
                '&market_type=BINANCE_COIN_BSC_TETHER' +
                '&market_type=MATIC_TETHER' +

                '&market_type=STELLAR_TETHER' +
                '&market_type=TRON_TETHER' +
                '&market_type=RIPPLE_TETHER' +
                '&market_type=DOGE_COIN_TETHER' +
                '&market_type=CELO_TETHER',
        }
    },
    methods: {
        getCurrency(value) {
            this.items[0].class = 'deActive'
            this.items[1].class = 'deActive'
            this.items[value].class = 'active'
            this.setData()
        },
        async setData() {
            this.state.loading = true
            const res = await this.$axios.get('/trades/market-info?' + (this.items[0].class == 'active' ? this.TomanCoin : this.TetherCoin), {
                params: {
                    weeklyDataIncluded: true,
                }
            })
            this.coins = res.content
        },
        goto(e) {
            this.$router.push('/profile/trade/' + (this.items[0].class == 'active' ? 'TOMAN' : 'TETHER') + `/${e}`)
        },
    },
    mounted() {
        this.setData()
    }
}
</script>

<style lang="scss" scoped>
.currencyTable {
    width: 100%;
    max-width: 1400px;
}

table {
    width: 100%;
    max-width: 1400px;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;
    border: 2px solid #4F4F4F;
    background: #4F4F4F;
}

//  table tr:last-of-type {
//    border-bottom-left-radius: 8px;
//       border-bottom-right-radius: 8px;
// }
.tr-heade {
    background-color: #001E3D;

    td {
        font-size: 14px;
        color: #FEFEFE;
    }

    td:last-child {
        border-top-left-radius: 6px;
    }
}

tr {
    height: 56px;

    td {
        color: #FCFCFC;
        font-size: clamp(11px, 2vw, 18px);
    }

    td:first-of-type {
        text-align: right;
        padding-right: 20px;
    }

    td:last-of-type {
        // text-align: left;
        padding-left: 20px;
    }
}

.tr-body {
    cursor: pointer;
}

.tr-body:hover {
    background-color: rgba(253, 215, 91, 0.3) !important;
}

.tr-body td:first-of-type {
    height: 51px;

    img {
        vertical-align: middle;
        margin-left: 10px;
    }
}

.tr-body:last-child {
    td:first-child {
        border-bottom-right-radius: 6px;
        //  img {
        //    vertical-align: middle;
        //    margin-left: 10px;
        //  }
    }

    td:last-child {
        border-bottom-left-radius: 6px
    }
}

.crypto-img {
    width: 32px;
    height: 32px;
}

.table-container {
    background: #4F4F4F;
    // border-radius: 8px 0px 8px 8px;
}

@media only screen and (max-width:700px) {

    .buyPrice,
    .sellPrice,
    .chart,
    .crypto-img {
        display: none;
    }
}
</style>
