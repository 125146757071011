<template>
<div class="wallet">
    <transition name="fade-modal">
        <DepositToman v-if="modal==='DepositeToman'" @closeModaleEmit="modal=''" />
        <WithdrawalToman v-if="modal==='WidthdrawalToman'" @closeModaleEmit="closeModal" :cash="cash" @two-fa="openTwoStepModal" :role="role" />
        <DepositBit v-if="modal==='DepositBit'" @closeModaleEmit="closeModal" :related-coin="relatedCoinVar" :cash="cash" @two-fa="openTwoStepModal" :wage="wage" :nets="nets" :exchange='false' />
        <WithdrwalBit :related-coin="relatedCoinVar" :cash="cash" :role="role" :wage="wage" v-if="modal==='WithdrawalBit'" @closeModaleEmit="modal=''" @two-fa="openTwoStepModal" :nets="nets" :exchange='false' />
    </transition>
    <transition name="modal-fade">
        <SetTwoStepModal v-if="twoStep" :two-step="twoStep" @close="twoStep = ''" @submit="submitWithdrawRequest" />
    </transition>
    <span class="wallet-title">
        کیف پول 
    </span>
    <div class="wallet-toman">
        <inline-svg class="toman-hexa" :src="require('../../../assets/Profile/wallethx.svg')" fill="#0A2139">
        </inline-svg>
        <img class="flag" src="@/assets/Profile/flag.png" alt="">
        <span>
            تومان
        </span>
        <span class="number-font">
            {{$toLocal(wallets[0][1].credit)}} <span class="unit"> تومان </span>
        </span>
        <div class="toman-btn-container">
            <button v-for="(toman , index) in tomanBTN" :key="index" @click="OpenModal(index,$toLocal(wallets[0][1].credit))" class="depositeWith-btn" :class=toman.class>
                {{toman.title}}
            </button>
        </div>
    </div>
    <div class="wallet-crypto-nav">
        <span class="nav-items" v-for="(nav, index) in nav" :key="index">
            {{nav.title}} <span class="title2">={{nav.title2}}</span>
        </span>
    </div>
    <div class="wallet-crypto-container">
        <template v-for="(item , index) in wallets.filter(e => e[0]!=='TOMAN')">
            <div class="wallet-crypto" :key="index">
                <img class="crypto-img" :src="'/coins/'+ item[0]+ '.png'" alt="">
                <inline-svg class="img-container" :src="require('../../../assets/Profile/wallethx.svg')"></inline-svg>
                <span class="crypto-name">
                    {{$coins[item[0]].persianName}}
                </span>
                <div class="balance">
                    <span class="number-font" dir="ltr">
                        {{item[1].wholePriceOfCredit}}&nbsp;
                        &nbsp;=&nbsp;
                        {{$coins[item[0]].code}}
                        {{item[1].credit}}

                    </span>
                    <span class="number-font balance-toman">
                    </span>
                </div>
                <div class="btn-container">
                    <button class="depositeWith-btn withdrawal-btn" @click="openWithModal([item[0], credit(item[1])] , index)">
                        <p>برداشت</p>
                    </button>
                    <button class="depositeWith-btn deposite-btn" @click="openDepModal([item[0], credit(item[1])] , index)">
                        <p>واریز</p>
                    </button>
                    <button class="depositeWith-btn trade-btn" @click="goto(item[0])">
                        <p>معامله</p>
                    </button>
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import DepositToman from './WalletsModal/DepositToman.vue'
import WithdrawalToman from './WalletsModal/WithdrawalToman.vue'
import DepositBit from './WalletsModal/DepositBit.vue'
import WithdrwalBit from './WalletsModal/WithdrwalBit.vue'
import SetTwoStepModal from './WalletsModal/SetTwoStepModal.vue'
export default {
    name: 'Wallet',
    components: {
        InlineSvg,
        DepositToman,
        WithdrawalToman,
        DepositBit,
        WithdrwalBit,
        SetTwoStepModal
    },
    data() {
        return {

            buttons: [{
                    title: "برداشت",
                    class: "withdrawal-btn",
                    open: 'WithdrawalBit'
                    //  click : () => this.deposit()
                },
                {
                    title: "واریز",
                    class: "deposite-btn",
                    open: 'DepositBit'
                },
                {
                    title: "معامله",
                    class: "trade-btn",
                    link: "trade"
                }
            ],
            tomanBTN: [{
                    title: "واریز",
                    class: "deposite-toman",
                    open: 'DepositeToman'
                },
                {
                    title: "برداشت",
                    class: "widthdrawal-toman",
                    open: 'WidthdrawalToman'
                }
            ],
            nav: [{
                    title: "ارز"
                },
                {
                    title: "موجودی",
                    title2: "معادل تومان"
                },
                {
                    title: "عملیات"
                }
            ],
            showWithModal: false,
            showDepModal: false,
            showRWithModal: false,
            showRDepModal: false,
            wallets: [],
            relatedCoinVar: '',
            cash: 0,
            twoStep: '',
            transaction: {},
            coins: [],
            wage: '',
            role: {},
            loading: false,
            modal: ''

        }
    },
    computed: {
        amount() {
            return e => this.$toLocal(e.credit) || '0'
        },
        price() {
            return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].totalBalance : e[1].wholePriceOfCredit, 0) || '0'
        },
        blocked() {
            return e => this.$toLocal(e[1].blockedCredit) || '0'
        },
        credit() {
            return e => this.$toLocal(e.credit) || '0'
        },
    },

    methods: {
        OpenModal(index,cash) {
            this.cash = cash
            this.wage = this.coins.find((a) => a.coin === "TOMAN").transferFee
             this.role = this.coins.find((a) => a.coin === "TOMAN")
            this.modal = this.tomanBTN[index].open
        },
        OpenModalBit(e, index) {
            this.modal = this.buttons[index].open
            this.relatedCoinVar = e
            this.cash = e[1]
            this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
            this.role = this.coins.find((a) => a.coin === e[0])
        },
        openWithModal(e) {
            this.nets = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails
            this.relatedCoinVar = e[0]
            this.cash = e[1]
            this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
            this.role = this.coins.find((a) => a.coin === e[0])
            this.modal = 'WithdrawalBit'

        },
        openDepModal(e) {

            this.relatedCoinVar = e
            // this.cash = e[1]
            // this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
            this.modal = 'DepositBit'
            this.nets = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails
        },
        closeModal(value) {
            this.modal = value
        },
        depositModal(e) {
            this.relatedCoinVar = e
            if (e[0] !== 'TOMAN') {
                this.showDepModal = true
            } else {
                this.showRDepModal = true
            }
        },
        withdrawModal(e) {
            this.relatedCoinVar = e[0]
            this.cash = e[1]
            this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
            if (e[0] !== 'TOMAN') {
                this.showWithModal = true
            } else {
                this.showRWithModal = true
            }
        },
        openTwoStepModal(e) {
            this.transaction = e[0]
            this.twoStep = e[1]
        },
        closemodal() {
            this.showWithModal = false
            this.showDepModal = false
            this.showRWithModal = false
            this.showRDepModal = false
            this.twoStep = ''
        },
        async getWallets() {
            this.state.loading = true
            // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
            this.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                let entireRes = Object.entries(res)
                const sortCoins = this.$walletSortCoins
                entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = entireRes
            }
            this.loading = false
        },
        async submitWithdrawRequest(code) {
            this.state.loading = true
            this.transaction.code = code
            const [res, ] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
            if (res) {
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                this.closemodal()
            }
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content

        },
        async checkWallet(e) {
            let query = {
                relatedCoin: e
            }
            if (e === 'TETHER') {
                const res = await this.$error('', 'شبکه مورد نظر را انتخاب کنید', 'info', 'اتریومی', 'ترونی')
                if (res.dismiss !== 'backdrop') {
                    query.tokenType = res.isConfirmed ? 'ERC20' : 'TRC20'
                } else {
                    return;
                }
            }
            if (this.$etcChannelCoin.includes(e)) {
                query.txid = await prompt('TxId : شناسه تراکنش')
                if (!query.txid) {
                    return;
                }
            }
            this.state.loading = true
            const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
                params: query
            })
            let text
            if (res.baseDTO.amount) {
                ++this.state.requestChanged
                text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[e]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
            } else {
                text = 'مقدار واریزی شناسایی نشد'
            }
            this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
        },
        goto(e) {
            this.$router.push('/profile/trade/' + 'TOMAN' + `/${e}`)
        },

    },
    mounted() {
        this.state.loading = true
        this.getWallets()
        this.getData()
    },
}
</script>

<style lang="scss" scoped>
.wallet {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: #001E3D;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px;
    margin-top: 8px;

    .wallet-title {
        margin: 8px 0 32px;
        font-weight: 500;
        font-size: 18px;
        color: #FEFEFE;
    }

    .wallet-toman {
        background: #153F5A;
        border-radius: 6px 0px 0px 6px;
        min-height: 82px;
        width: calc(100% - 50px);
        margin-right: auto;
        position: relative;
        // max-width: 1111px;
        display: flex;
        margin-bottom: 48px;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 24px;
        padding-right: 90px;
        font-weight: bold;
        font-size: 16px;
        color: #FEFEFE;
        flex-wrap: wrap;
        row-gap: 20px;

        .toman-hexa,
        .flag {
            position: absolute;
        }

        .toman-hexa {
            right: -25px;
            top: 0;
        }

        .flag {
            right: -3px;
            top: 15px;
        }

        .unit {
            font-weight: normal;
            font-size: 16px;
        }
    }
}

.wallet-crypto-nav {
    padding: 15px 24px;
    padding-right: 70px;
    width: calc(100% - 50px);
    // max-width: 1115px;
    margin-right: auto;
    color: #BDBDBD;
    font-weight: normal;
    font-size: 14px;
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 2fr 4fr 4fr;

    .nav-items {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
}

.wallet-crypto-container {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
}

.wallet-crypto {
    background: #153F5A;
    border-radius: 6px 0px 0px 6px;
    min-height: 62px;
    width: calc(100% - 50px);
    // max-width: 1115px;
    margin-right: auto;
    position: relative;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    padding: 15px 24px;
    padding-right: 70px;
    column-gap: 40px;
    // row-gap: 40px;
    // display: grid;
    // grid-template-columns: 2fr 4fr 5fr;
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 20px;
    // flex-wrap: wrap;
}

.withdrawal-btn {
    background: linear-gradient(270deg, rgba(255, 47, 1, 0.97) 0%, rgba(255, 61, 0, 0.979333) 32.6%, rgba(255, 92, 0, 0.984313) 64.37%, #FF3D00 100%);
    color: #E43700;

    p {
        background: #153F5A;
    }
}

.deposite-btn {
    background: linear-gradient(90deg, #9DE700 0%, #5AB400 51.76%, #91D600 101.82%);
    color: #B9FFA0;

    p {
        background: #153F5A;
    }
}

.trade-btn {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    color: #FCFCFC;
}

.withdrawal-btn,
.deposite-btn,
.trade-btn {
    box-sizing: border-box;
    padding: 2px;
    height: 32px;
    // width: 120px; 
    border-radius: 6px;

    p {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
    }
}

.btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    row-gap: 24px;
    column-gap: 24px;
    flex-grow: 1;
    flex-basis: 284px;
}

.crypto-name {
    font-weight: bold;
    font-size: 14px;
    color: #FEFEFE;
    // flex-grow: 1;
    width: 200px;
}

.balance {
    display: flex;
    column-gap: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #FEFEFE;
    flex-grow: 1;
}

.balance-toman {
    font-weight: normal;
}

.crypto-img {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -3px;
    top: 10px;
    z-index: 1;
}

.img-container {
    width: 75px;
    height: 60px;
    position: absolute;
    right: -21px;
    top: 0;
    fill: #143F5A !important;

    svg path {
        fill: #143F5A !important;
    }
}

.toman-btn-container {
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
    row-gap: 16px;

    .deposite-toman,
    .widthdrawal-toman {
        font-weight: bold;
        font-size: 14px;
        color: #FCFCFC;
        width: 136px;
        height: 40px;
        border-radius: 6px;
    }

    .deposite-toman {
        background: linear-gradient(90deg, #9DE700 0%, #5AB400 51.76%, #91D600 101.82%);
    }

    .widthdrawal-toman {
        background: linear-gradient(270deg, rgba(255, 47, 1, 0.97) 0%, rgba(255, 61, 0, 0.979333) 32.6%, rgba(255, 92, 0, 0.984313) 64.37%, #FF3D00 100%);
    }
}

.depositeWith-btn {
    // flex-grow: 1;
    width: 136px;
    // max-width: 120px;
}

a {
    flex-grow: 1;

    button {
        width: 100%;
    }
}

@media only screen and (max-width:1024px) {
    .wallet-crypto {
        flex-wrap: wrap;
    }

    .wallet-crypto-nav,
    .toman-hexa,
    .flag {
        display: none;
    }

    .wallet-toman {
        padding-right: 10px !important;
        width: 100% !important;
    }

    .wallet-crypto {
        width: 100%;
        padding-right: 10px;
    }

    .toman-btn-container {
        justify-content: center;
    }

    .crypto-img,
    .img-container {
        display: none;
    }

    .wallet-toman,
    .wallet-crypto {
        border-radius: 6px !important;
    }
}

@media only screen and (max-width:600px) {

    .crypto-img,
    .img-container,
    .title2,
    .balance-toman {
        display: none;
    }

    .btn-container {
        flex-direction: column;

        a {
            width: 100%
        }
    }
}
</style>
