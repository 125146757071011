<template>
<div class="sell-buy">
    <span class="line-title">
        صف خرید و فروش
    </span>
    <div class="table">
        <div class="tr-heade">
            <span v-for="(head , index) in head" :key="index">
                {{head.title}}
            </span>
        </div>
        <div v-if="!tableContentsBuy.length">
            <p style="width:100%;text-align:center;color:green">در حال حاضر اطلاعاتی وجود ندارد</p>
        </div>
        <div class="tr-body" v-for="(tableContent , index) in tableContentsBuy" :key="index" :style="{backgroundImage: `linear-gradient(90deg, transparent  ${100-tableContent.executedPercent}%, rgba(51, 255, 0, 0.6) ${100-tableContent.executedPercent}% ,rgba(51, 255, 0, 0.6) 101%)`}">
            <span class="number-font buy">
                {{$toLocal(tableContent.unitPrice,tradeTo === 'TOMAN' ? 0 : $prcesion[tradeFrom])}}
            </span>
            <span class="number-font">
                {{$toLocal(tableContent.volume,$decimal[tradeFrom])}}
            </span>
            <span class="number-font">
                {{$toLocal(tableContent.wholePrice,$decimal[tradeTo])}}
            </span>
        </div>
    </div>
    <div class="number-font total-number buy">
    </div>
    <div class="table">
        <div v-if="!tableContentsSell.length">
            <p style="width:100%;text-align:center;color:red">در حال حاضر اطلاعاتی وجود ندارد</p>
        </div>
        <div class="tr-body " v-for="(tableContent , index) in tableContentsSell" :key="index" :style="{backgroundImage: `linear-gradient(90deg, transparent ${100-tableContent.executedPercent}%, rgba(255, 47, 1, 0.63) ${100-tableContent.executedPercent}% ,rgba(255, 47, 1, 0.63) 101%)`}">
            <span class="number-font sell">
                {{$toLocal(tableContent.unitPrice,tradeTo === 'TOMAN' ? 0 : $prcesion[tradeFrom])}}
            </span>
            <span class="number-font">
                {{$toLocal(tableContent.volume,$decimal[tradeFrom])}}
            </span>
            <span class="number-font">
                {{$toLocal(tableContent.wholePrice,$decimal[tradeTo])}}
            </span>
        </div>
    </div>
</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    // precision,
    Loop
} from "@/library/reuseableFunction";

export default {
    name: 'SellBuyLine',
    components: {

    },
    data() {
        return {
            head: [{
                    title: "قیمت"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "قیمت کل"
                }
            ],
            tableContentsSell: [],
            tableContentsBuy: [],
            loading: false,
            settings: {
                suppressScrollX: true
            },
            loop: ''

        }
    },
    computed: {
        tradeFrom,
        tradeTo,
        precision() {
            return this.$decimal[this.tradeFrom] || 8
        }
    },

    methods: {
        async getMarketDepth() {
            const [res, ] = await this.$http.get('/orders/order-book', {
                params: {
                    marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                }
            })
            if (res) {
                this.tableContentsSell = res.sellOrderBook.slice(0, 11).sort((a , b) =>{a.unitPrice > b.unitPrice} )
                this.tableContentsBuy = res.buyOrderBook.slice(0, 11).sort((a , b) =>{return a.unitPrice > b.unitPrice} )
                this.state.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                this.state.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
            }
            this.loading = false
        },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '-' + this.tradeTo)
            this.loop.start()
        } else {
            this.getMarketDepth()
        }
        // this.getMarketDepth()
    },
    beforeDestroy() {
        this.loop.stop()
    },
}
</script>

<style lang="scss" scoped>
.sell-buy {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    width: 260px;
    background: var(--background-child);
    border: 2px solid var(--border-color);
    border-radius: 6px;
}

.line-title {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-strong);
    margin: 12px 0;
    text-align: center;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.tr-heade {
    height: 30px;
    font-weight: normal;
    font-size: 12px;
    color: var(--font-strong);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}

.total-number {
    width: 100%;
    height: 36px;
    background: var(--background-line-total);
    display: flex;
    align-items: center;
    justify-content: center;
    // color: #B9FFA0;
}

.tr-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    align-items: flex-end;
    font-weight: normal;
    font-size: 12px;
    color: var(--font-strong);
    height: 23px;
}

.table {
    max-height: 230px;
    overflow: scroll;
}
.table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

// .buy {
//   color: #B9FFA0;
// }
.sell {
    color: #E43700;
}
@media only screen and (max-width: 600px) {
   .sell-buy  {
        width: 100%;
    }
}
</style>
