<template>
<div class="carosel">
    <carousel :rtl="true" :per-page="4" :space-padding="10" :interval="3000" :loop='true' >
        <slide v-for="(coin , index) in cryptoList" :key="index">
            <div class="slide">
                <div class="information">
                    <span class="crypto-name">
                        {{$coinLabel[coin.relatedCoin]}}
                    </span>
                    <span class="crypto-price number-font">
                        {{$toLocal(coin.lastPrice,0)}}
                    </span>
                    <span class="crypto-change number-font" :class="{'negative-change':coin.last24HourChange<0,'positive-change':coin.last24HourChange>0}">
                        %
                        {{$toLocal(Math.abs(coin.last24HourChange),1)}}
                        <span v-if=" coin.last24HourChange < 0"> - </span>
                        <span v-if="coin.last24HourChange > 0"> + </span>
                    </span>
                </div>
                <div class="image">
                    <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" class="image-slide" alt="">
                    <inline-svg class="img-container" :src="require('../../assets/Profile/wallethx.svg')">
                    </inline-svg>
                </div>
            </div>
        </slide>
    </carousel>
</div>
</template>

<script>
import {
    Carousel,
    Slide
} from 'vue-carousel';
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'Carosel',
    components: {
        Carousel,
        Slide,
        InlineSvg
    },
    data() {
        return {
            cryptoList: [],
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +

                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +

                '&market_type=TETHER_TOMAN',
        }
    },
    methods: {
        async setData() {
            const res = await this.$axios.get('/trades/market-info?' + this.TomanCoin, {
                params: {
                    weeklyDataIncluded: true,
                }
            })
            this.cryptoList = res.content
        },
    },
    mounted() {
        this.setData()
    }
}
</script>

<style lang="scss" scoped>
.carosel {
    width: 100%;
    // max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 70px 0;
    max-width: 1400px;
}

.slide {
    background: #001E3D;
    border-radius: 6px;
    // width: 282px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin: 0 10px;
}

@supports not (backdrop-filter: blur(10px)) {
    .slide {
        background: #252833 !important;
    }
}

.information,
.image {
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 4px;
}

.image {
    width: 44px;
    height: 44px;
    position: relative;
}

.image-slide {
    width: 44px;
    height: 44px;
    z-index: 1;
}

.img-container {
    position: absolute;
    width: 80px;
    height: 75px;
    right: -19px;
}

.crypto-name {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.crypto-price {
    font-weight: bold;
    font-size: 20px;
    color: #FEFEFE;
    ;
}

.crypto-change {
    font-weight: normal;
    font-size: 16px;
    // direction: ltr;
    color: white;
}

.negetive-change {
    color: rgb(247, 131, 131);
}

.positive-change {
    color: #80CC52;
}

svg {
    vertical-align: middle;
}

path {
    border: 1px solid red
}

path {
    stroke: red;
    stroke-width: 2px;
    stroke-linejoin: round;
}
</style><style>
.VueCarousel-inner {
    /* flex-basis: 307px !important; */
}

.VueCarousel {
    direction: revert !important;
    /* width: 90%;
    margin: auto; */
}

.VueCarousel-pagination {
    height: 10px !important;
}

.VueCarousel-dot {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: none;
    background-clip: content-box;
    box-sizing: content-box;
    padding: 0;
    border-radius: 100%;
    outline: none;
    background-color: black !important;
    margin-top: 0 !important;
}

.VueCarousel-dot--active {
    background-color: gainsboro !important;
}

.VueCarousel-dot-container {
    margin-top: 10px !important;
}

.VueCarousel-pagination {
    width: 100%;
    text-align: center;
    display: none;
}

.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: -1em;
    right: -1em;
    height: 2em;
    box-shadow: none !important;
    z-index: 2;
}

@media only screen and (max-width:700px) {
    .vueperslides__bullets {
        display: none !important;
    }
}
</style>
