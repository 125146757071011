<template>
<div class="help-footer">
    <svg height="100" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="100,100 0,100 0,0" />
    </svg>
    <div class="footer">

        <div class="footer-container">
            <div class="footer-rotate">
                <div class="name-container">
                    <img class="footer-img" src="@/assets/Home/logo1.png" alt="">
                    <div>
                        <p class="company-name">
                            بی بیتکس
                        </p>
                        <p class="describe">
                            کامل ترین پلتفرم خرید و فروش رمزارزها
                        </p>
                    </div>
                </div>
            </div>
            <div class="list-container">
                <div class="list">
                    <span class="list-title">
                        دسترسی سریع
                    </span>
                    <span class="li" v-for="(item , index) in title1" :key="index" @click="$router.push(item.link)">
                        {{item.title}}
                    </span>
                </div>
                <div class="list">
                    <span class="list-title">
                        تماس با ما
                    </span>
                    <span class="li">
                       آدرس :  {{info.address}}
                    </span>
                    <span class="li">
                         تلفن : {{info.number}}
                    </span>
                    <span class="li">
                       ایمیل :  {{info.email}}
                    </span>
                </div>
                <div class="list">
                    <span class="list-title">
                        شبکه های اجتماعی
                    </span>
                    <span class="li">

                        <a class="links-container" :href="info.telegram.includes('http')? info.telegram: `https://telegram.org/${info.telegram}`" target="_blank">
                            <img src='../../assets/Home/telegram.png'>
                            <span>تلگرام</span>
                        </a>
                    </span>
                    <span class="li">
                        <a class="links-container" :href="info.instagram.includes('http')? info.telegram: `https://telegram.org/${info.instagram}`" target="_blank">
                            <img src='../../assets/Home/instagram.png'>
                            <span>اینستاگرام</span>
                        </a>
                    </span>
                    <span class="li">
                        <a class="links-container" :href="info.twitter.includes('http')? info.telegram: `https://telegram.org/${info.twitter}`" target="_blank">
                            <img src='../../assets/Home/twiter.png'>
                            <span>توییتر</span>
                        </a>
                    </span>
                    <span class="li">
                        <a class="links-container" :href="info.linkedin.includes('http')? info.telegram: `https://telegram.org/${info.linkedin}`" target="_blank">
                            <img src='../../assets/Home/whatsapp.png'>
                            <span>واتساپ</span>
                        </a>
                    </span>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Footer',
    components: {

    },
    data() {
        return {
            title1: [{
                    title: "بلاگ"
                },
                {
                    title: "درباره ما",
                    link: "/about-us"
                },
                {
                    title: "کارمزد",
                    link: "/fees"
                },
                {
                    title: "قوانین و مقررات",
                    link: "/policy"
                },
                {
                    title: "راهنمای استفاده",
                    link: "/guide"
                }
            ],
            title2: [{
                    title: "آدرس : خیابان مطهری، نرسیده به میرزای شیرازی، پلاک 299 واحد 3"
                },
                {
                    title: "021333333"
                },
                {
                    title: "vistaapp.ir"
                }
            ],
            title3: [{
                    img: require('../../assets/Home/telegram.png'),
                    title: "تلگرام"
                },
                {
                    img: require('../../assets/Home/instagram.png'),
                    title: "اینستاگرام"
                },
                {
                    img: require('../../assets/Home/twiter.png'),
                    title: "توییتر"
                },
                {
                    img: require('../../assets/Home/whatsapp.png'),
                    title: "واتساپ"
                }
            ],
            home: true,
            logedin: false,
            info: {
                address: "",
                appAndroid: "",
                appIOS: "",
                email: "",
                instagram: "",
                linkedin: "",
                number: "",
                telegram: "",
                twitter: ""
            }
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.help-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

svg {
    fill: #001E3D;
}

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    background: #001E3D;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 10px;
}

.footer-container {
    width: 100%;
    max-width: 1400px;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    flex-wrap: wrap;
}

.footer-rotate {
    min-width: 280px;
    flex-grow: 1;
}

.footer-img {
    width: 110px;
    height: 110px;
}

.name-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;
    min-height: 300px;
}

.company-name {
    font-size: 36px;
    color: #FFC04F;
}

.describe {
    color: #F2F2F2;
    font-size: 18px;
}

.list-title {
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    margin: 5px 0;
}

.li {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    column-gap: 12px;
    align-items: center;
    cursor: pointer;
}

.list-container {
    display: flex;
    flex-grow: 1;
    column-gap: 50px;
    min-width: 300px;
    flex-wrap: wrap;
    flex-grow: 1;
    row-gap: 40px;

    .list {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        row-gap: 10px;
        max-width: 200px;
    }
}

.links-container {
    display: flex;
    column-gap: 10px;
}
</style>
