<template>
<div class="faq">
    <p class="faq-title">
        سوالات متداول
    </p>
    <div class="questions-container">
        <div class="question" v-for="(message,index) in messages" :key="index">
            <div class="question-box" @click.prevent="toggle(index)">
                <p>
                    {{message.question}}
                </p>
                <img  src="@/assets/Home/Vector.svg"  :style="{transform: [showmessage[index] ?  'rotate(180deg)':'' ]}" alt="">
            </div>
            <div class="answer-box"  :style="{maxHeight: showmessage[index]? (messageheight[index] + 'px') : ''}">
                <p class="">
                    {{message.answer}}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'FAQ',
    components: {

    },
    data() {
        return {
            showmessage: [],
            messageheight: [],
            messages: [],
        }
    },
    methods: {
        toggle(e) {
            this.messageheight[e] = document.getElementsByClassName('answer-box')[e].firstChild.clientHeight + 20
            this.showmessage[e] = !this.showmessage[e];
            this.$forceUpdate()
        },
        async getFaq() {
            this.state.loading = true
            this.messages = await this.$axios.get('/faq')
            for (let i = 0; i < this.messages.length; i++) {
                this.showmessage[i] = false
                // this.questions[0].svgClass='deActive'
            }
            this.state.loading = false

        }
    },
    mounted() {
        this.getFaq()
    }
}
</script>

<style lang="scss" scoped>
.faq {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
    box-sizing: border-box;
    padding: 0 10px;
}

.faq-title {
    font-weight: bold;
    font-size: 24px;
    color: #F2F2F2;
    margin: 32px 0;
}

.questions-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.question {
    background: #001E3D;
    border-radius: 8px;

}

.question:hover {
    background: #1c3f61;
}

.question-box {
    height: 64px;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 32px;
}

.answer-box {
    max-height: 0;
    color: #FFFFFF;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 32px;
}

div.deActive {
    display: none;
}

div.active {
    display: block;
}

img {
    width: 17px;
    height: 10px;
}

.active-svg {
    transform: rotate(-180deg);
    transition: ease-in 0.1s;
}
</style>
